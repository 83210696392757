import { defineStore } from "pinia"
import junkApi from "@/api/Junk"
import { useSnackbarStore } from "@/stores/Snackbar"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { errorSnackbar, successSnackbar } from "@/utils/SnackbarBuilder"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"

export const useAdministratorStore = defineStore("administrator", {
  state: () => ({
    isLoadingOperatingUnitFranchisesAndSatellites: false,
    operatingUnitFranchisesAndSatellites: [],
    isLoadingCreateEmployee: false,
    isLoadingDiscounts: false,
    discounts: [],
    isLoadingSpecialtyItems: false,
    specialtyItems: []
  }),
  getters: {
    getIsLoadingOperatingUnitFranchisesAndSatellites() {
      return this.isLoadingOperatingUnitFranchisesAndSatellites
    },
    getOperatingUnitFranchisesAndSatellites() {
      return this.operatingUnitFranchisesAndSatellites.slice().sort((a, b) => a.displayName.localeCompare(b.displayName))
    },
    getFranchisesAndSatellites() {
      return (
        this.operatingUnitFranchisesAndSatellites
          .slice()
          .flatMap(ou => ou.children)
          .sort((a, b) => a.displayName.localeCompare(b.displayName)) ?? []
      )
    },
    getIsLoadingCreateEmployee() {
      return this.isLoadingCreateEmployee
    },
    getIsLoadingDiscounts() {
      return this.isLoadingDiscounts
    },
    getDiscounts() {
      return this.discounts.slice()
    },
    getActiveDiscounts() {
      return this.discounts.filter(d => formatAsDateOnly(d.endDate) >= todayAsDate() || d.endDate === null)
    },
    getIsLoadingSpecialtyItems() {
      return this.isLoadingSpecialtyItems
    },
    getSpecialtyItems() {
      return this.specialtyItems.slice()
    },
    getActiveSpecialtyItems() {
      return this.specialtyItems.filter(si => si.isActive)
    }
  },
  actions: {
    async fetchAllOperatingUnitFranchisesAndSatellites() {
      this.isLoadingOperatingUnitFranchisesAndSatellites = true
      return await junkApi
        .fetchAllOperatingUnitFranchisesAndSatellites()
        .then(response => {
          this.operatingUnitFranchisesAndSatellites.splice(0, this.operatingUnitFranchisesAndSatellites?.length ?? 0, ...response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingOperatingUnitFranchisesAndSatellites = false
        })
    },
    async createOperatingUnit(dto) {
      this.isLoadingOperatingUnitFranchisesAndSatellites = true
      return await junkApi
        .createOperatingUnit(dto)
        .then(response => {
          this.operatingUnitFranchisesAndSatellites.push(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingOperatingUnitFranchisesAndSatellites = false
        })
    },
    updateOperatingUnitFranchisesAndSatellites(data) {
      const idx = this.operatingUnitFranchisesAndSatellites.findIndex(ou => ou.operatingUnitId === data.operatingUnitId)
      if (~idx) {
        this.operatingUnitFranchisesAndSatellites.splice(idx, 1, data)
      }
    },
    async updateOperatingUnit(dto) {
      this.isLoadingOperatingUnitFranchisesAndSatellites = true
      return await junkApi
        .updateOperatingUnit(dto)
        .then(response => {
          this.updateOperatingUnitFranchisesAndSatellites(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingOperatingUnitFranchisesAndSatellites = false
        })
    },
    async createFranchise(dto) {
      this.isLoadingOperatingUnitFranchisesAndSatellites = true
      return await junkApi
        .enrollFranchise(dto)
        .then(response => {
          this.updateOperatingUnitFranchisesAndSatellites(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingOperatingUnitFranchisesAndSatellites = false
        })
    },
    async updateFranchiseDetails(dto) {
      this.isLoadingOperatingUnitFranchisesAndSatellites = true
      return await junkApi
        .updateFranchiseDetails(dto)
        .then(response => {
          this.updateOperatingUnitFranchisesAndSatellites(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingOperatingUnitFranchisesAndSatellites = false
        })
    },
    async createSatelliteOffice(dto) {
      this.isLoadingOperatingUnitFranchisesAndSatellites = true
      return await junkApi
        .createSatelliteOffice(dto)
        .then(response => {
          this.updateOperatingUnitFranchisesAndSatellites(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingOperatingUnitFranchisesAndSatellites = false
        })
    },
    async updateSatelliteOfficeDetails(dto) {
      this.isLoadingOperatingUnitFranchisesAndSatellites = true
      return await junkApi
        .updateSatelliteOfficeDetails(dto)
        .then(response => {
          this.updateOperatingUnitFranchisesAndSatellites(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingOperatingUnitFranchisesAndSatellites = false
        })
    },
    async createEmployee(dto) {
      this.isLoadingCreateEmployee = true
      return await junkApi
        .createEmployee(dto)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingCreateEmployee = false
        })
    },
    async fetchNationalDiscounts() {
      this.isLoadingDiscounts = true
      return await junkApi
        .fetchNationalDiscounts()
        .then(response => {
          this.discounts = response
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDiscounts = false
        })
    },
    async createNationalDiscount(dto) {
      const snackbarStore = useSnackbarStore()
      this.isLoadingDiscounts = true
      return await junkApi
        .createNationalDiscount(dto)
        .then(response => {
          this.discounts.push(response)
          const successMessage = "Discount Created"
          const success = successSnackbar(successMessage)
          snackbarStore.addSnackbar(success)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDiscounts = false
        })
    },
    async updateNationalDiscount(dto) {
      this.isLoadingDiscounts = true
      return await junkApi
        .updateNationalDiscount(dto)
        .then(response => {
          const idx = this.discounts.findIndex(d => d.nationalDiscountId === dto.nationalDiscountId)
          if (~idx) {
            this.discounts.splice(idx, 1, response)
          }
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDiscounts = false
        })
    },
    setIsLoadingSpecialtyItems(isLoading) {
      this.isLoadingSpecialtyItems = isLoading
    },
    setSpecialtyItems(items) {
      this.specialtyItems = [...items]
    },
    addSpecialtyItem(item) {
      this.specialtyItems.push(item)
    },
    replaceSpecialtyItem(item) {
      const idx = this.specialtyItems.findIndex(si => si.administratorSpecialtyItemId === item.administratorSpecialtyItemId)
      if (~idx) {
        this.specialtyItems.splice(idx, 1, item)
      }
    },
    async fetchSpecialtyItems() {
      this.setIsLoadingSpecialtyItems(true)
      return await junkApi
        .fetchAdministratorSpecialtyItems()
        .then(response => {
          this.setSpecialtyItems(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingSpecialtyItems(false)
        })
    },
    async createSpecialtyItem(dto) {
      this.setIsLoadingSpecialtyItems(true)
      return await junkApi
        .createAdministratorSpecialtyItem(dto)
        .then(response => {
          this.addSpecialtyItem(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingSpecialtyItems(false)
        })
    },
    async updateSpecialtyItem(dto) {
      this.setIsLoadingSpecialtyItems(true)
      return await junkApi
        .updateAdministratorSpecialtyItem(dto)
        .then(response => {
          this.replaceSpecialtyItem(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackBar = errorSnackbar(errorMessage)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackBar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingSpecialtyItems(false)
        })
    }
  }
})
