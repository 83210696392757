<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-layout column>
        <div class="float-left mb-4">
          <span class="text-md-body-1 font-weight-bold" ref="job-add-on-selector-total-calculating" v-if="getIsLoadingAddOns"> Calculating...</span>
          <span class="text-md-body-1 font-weight-bold" ref="job-add-on-selector-total" v-else>
            Total Add-Ons Applied:
            <app-number-formatter ref="job-add-on-selector-total-formatter" currencySign="accounting" numberFormatStyle="currency" :amount="getAddOnsTotal" />
          </span>
        </div>
        <v-btn class="flex-fill mb-6" ref="job-modifiers-add-on-selector" color="primary" :loading="isLoading" :disabled="isDisabled" @click="selectAddOns">
          Add-Ons
        </v-btn>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { useJobStore } from "@/stores/Job"
import { mapState } from "pinia"

export default {
  name: "JobAddOnSelector",
  components: { AppNumberFormatter },
  computed: {
    ...mapState(useJobStore, ["getCurrentJob", "getJobAddOns", "getIsJobFieldsDisabled", "getIsLoadingFranchiseAndSatelliteOffices", "getIsLoadingJob", "getIsLoadingAddOns"]),
    getAddOnsTotal() {
      return this.getCurrentJob?.totalAddOns ?? 0
    },
    hasNoAvailableAddOns() {
      return this.getJobAddOns.length === 0
    },
    isLoading() {
      return this.getIsLoadingFranchiseAndSatelliteOffices || this.getIsLoadingJob || this.getIsLoadingAddOns
    },
    isDisabled() {
      return this.getIsJobFieldsDisabled || this.isLoading || this.hasNoAvailableAddOns
    }
  },
  methods: {
    selectAddOns() {
      this.$emit("select-add-ons")
    }
  }
}
</script>
