<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-layout column>
        <div class="float-left mb-4">
          <span class="text-md-body-1 font-weight-bold" ref="job-discount-selector-discount-calculating" v-if="getIsLoadingDiscount">Calculating...</span>
          <span class="text-md-body-1 font-weight-bold" ref="job-discount-selector-discount-total" v-else>
            Total Discount Applied:
            <app-number-formatter ref="job-discount-selector-discount-formatter" currencySign="accounting" numberFormatStyle="currency" :amount="getDiscountAmount" />
          </span>
        </div>
        <v-autocomplete
          ref="job-discount-selector-autocomplete"
          item-text="discountNameAndPromoCode"
          :label="getComponentTitle"
          :items="getActiveDiscounts"
          :value="getSelectedJobDiscount"
          :key="discountSelectorKey"
          :append-icon="getRemoveDiscountIcon"
          :disabled="isDisabled"
          :loading="isLoading"
          @change="applyDiscount"
          @click:append="removeDiscount"
          return-object
          outlined
        />
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { useJobStore } from "@/stores/Job"
import { mapState, mapActions } from "pinia"
import { putJobDiscountDto } from "@/api/dtos/JunkDtos"
import { isObjectEmpty } from "@/utils/ObjectHelpers"

export default {
  name: "JobDiscountSelector",
  components: { AppNumberFormatter },
  data() {
    return {
      discountSelectorKey: 0
    }
  },
  computed: {
    ...mapState(useJobStore, [
      "getCurrentJob",
      "getActiveDiscounts",
      "getSelectedJobDiscount",
      "getIsJobFieldsDisabled",
      "getIsLoadingFranchiseAndSatelliteOffices",
      "getIsLoadingJob",
      "getIsLoadingJobModifiers",
      "getIsLoadingDiscount"
    ]),
    getDiscountAmount() {
      return this.getCurrentJob?.discountAmount ?? 0
    },
    hasNoAvailableDiscounts() {
      return this.getActiveDiscounts.length === 0
    },
    hasDiscount() {
      return !isObjectEmpty(this.getSelectedJobDiscount)
    },
    getComponentTitle() {
      if (this.hasNoAvailableDiscounts) return "No Available Discounts"
      if (this.hasDiscount) return "Selected Discount"
      return "No Discount Applied"
    },
    getRemoveDiscountIcon() {
      return this.hasDiscount ? "mdi-close" : "mdi-blank"
    },
    isDisabled() {
      return this.getIsJobFieldsDisabled || this.isLoading || this.hasNoAvailableDiscounts
    },
    isLoading() {
      return this.getIsLoadingFranchiseAndSatelliteOffices || this.getIsLoadingJob || this.getIsLoadingJobModifiers || this.getIsLoadingDiscount
    }
  },
  methods: {
    ...mapActions(useJobStore, ["applyJobDiscount", "removeJobDiscount"]),
    async applyDiscount(item) {
      let dto = putJobDiscountDto(this.getCurrentJob.id, item.franchiseElectedDiscountId, this.$msal.getCurrentUserId())
      await this.applyJobDiscount(dto).catch(() => this.handleKeySelectorChange())
    },
    handleKeySelectorChange() {
      this.discountSelectorKey === 0 ? (this.discountSelectorKey = 1) : (this.discountSelectorKey = 0)
    },
    async removeDiscount() {
      if (this.hasDiscount) {
        await this.removeJobDiscount(this.$msal.getCurrentUserId())
      }
    }
  }
}
</script>
