<template>
  <base-dialog
    ref="app-create-customer-dialog-root"
    title="Create Customer"
    dialog-image="mdi-account-plus-outline"
    :is-dialog-visible="isDialogVisible"
    :max-dialog-width="getMaxDialogWidth"
  >
    <template class="flex-fill" v-slot:content>
      <v-form ref="app-create-customer-dialog-form" v-if="!isMatchesContentVisible" class="ma-0 pa-0">
        <v-row dense>
          <v-col>
            <v-autocomplete
              ref="app-create-customer-franchise-selector"
              label="Create Job In Franchise *"
              item-text="franchiseNameAndNumber"
              :value="selectedFranchise"
              :items="getUserActiveFranchises"
              :rules="selectedFranchiseRule"
              @change="updateSelectedFranchise"
              return-object
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-select
              class="cursor_pointer"
              ref="app-create-customer-dialog-customer-type-selector"
              label="Customer Type *"
              item-text="name"
              item-value="id"
              v-model="selectedCustomerType"
              :items="getCustomerTypes"
              :rules="isRequiredRules"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
            />
          </v-col>
        </v-row>
        <v-row ref="app-create-customer-dialog-business-name" v-if="isBusinessesSelected" dense>
          <v-col>
            <v-text-field ref="app-create-customer-dialog-business-name-text" label="Business Name *" counter="100" v-model="businessName" :rules="isRequiredRules" outlined />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              ref="app-create-customer-dialog-first-name-text"
              label="First Name *"
              autocomplete="null"
              counter="50"
              v-model="firstName"
              :rules="isRequiredRules"
              outlined
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-text-field
              ref="app-create-customer-dialog-last-name-text"
              label="Last Name *"
              autocomplete="null"
              counter="50"
              v-model="lastName"
              :rules="isRequiredRules"
              outlined
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-phone-input
              class="d-flex"
              ref="app-create-customer-dialog-phone-primary-text"
              label="Primary Phone Number *"
              v-model="phonePrimary"
              :rules="isRequiredRules"
              outlined
              validate-on-blur
            />
          </v-col>
          <v-col cols="3">
            <v-text-field ref="app-create-customer-dialog-phone-primary-ext-text" label="Extension" type="number" counter="6" v-model="phonePrimaryExtension" outlined />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-phone-input class="d-flex" ref="app-create-customer-dialog-phone-secondary-text" label="Secondary Phone Number" v-model="phoneSecondary" outlined validate-on-blur />
          </v-col>
          <v-col cols="3">
            <v-text-field ref="app-create-customer-dialog-phone-secondary-ext-text" label="Extension" v-model="phoneSecondaryExtension" outlined counter="6" />
          </v-col>
        </v-row>

        <v-row dense class="flex-fill">
          <div ref="app-create-customer-dialog-email" v-if="isEmailOptedOut" class="mt-n2 ps-1 pb-2" v-html="franchiseEmailUsedMessage" style="color: var(--v-primary-base)" />
          <v-col class="flex-column">
            <v-text-field
              class="flex-fill"
              ref="app-create-customer-dialog-email-text"
              label="Email Address *"
              autocomplete="null"
              counter="50"
              v-model="email"
              :rules="emailRules"
              :disabled="isEmailOptedOut"
              @keyup.enter="handleConfirmationAction"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="3" class="flex-column">
            <v-checkbox
              ref="app-create-customer-dialog-email-opted-out-checkbox"
              class="align-center mt-1"
              label="Refused to Provide"
              :false-value="false"
              :true-value="true"
              :value="isEmailOptedOut"
              @change="setIsEmailOptedOut($event)"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-select
              class="cursor_pointer"
              ref="app-create-customer-dialog-preferred-contact-method-selector"
              label="Preferred Method of Contact *"
              item-value="id"
              item-text="name"
              v-model="preferredMethodOfContactId"
              :items="getPreferredMethodOfContactsFiltered"
              :rules="preferredMethodOfContactRules"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
            />
          </v-col>
        </v-row>

        <v-row v-if="isBusinessesSelected">
          <v-col cols="auto">
            <v-checkbox class="mt-n2" ref="app-edit-contact-dialog-tax-exempt-checkbox" :disabled="isTaxExemptDisabled" v-model="isTaxExempt" label="Tax Exempt" />
          </v-col>
        </v-row>

        <v-row ref="app-create-customer-dialog-ein" v-if="isBusinessesSelected" dense class="align-baseline">
          <v-col>
            <v-text-field
              class="mt-n2"
              ref="create-customer-dialog-ein-text"
              placeholder="XX-XXXXXXX"
              :value="employerIdentificationNumber"
              @input="formatEin"
              :rules="[einRule]"
              label="Employer Identification Number"
              counter="10"
              outlined
            />
          </v-col>
        </v-row>
        <v-row ref="app-create-customer-dialog-charity" v-if="isBusinessesSelected" class="mt-n4">
          <v-col cols="auto">
            <v-btn
              ref="app-create-customer-dialog-validate-charity-button"
              elevation="1"
              color="primary"
              :disabled="isCharityPreflightButtonDisabled"
              outlined
              @click="validateEinWrapper"
            >
              Validate Charity
            </v-btn>
            <v-icon ref="app-create-customer-dialog-charity-icon" class="ms-4" :color="charityIconColor">mdi-charity</v-icon>
          </v-col>
        </v-row>
      </v-form>
      <div ref="app-create-customer-dialog-matching-customers" v-else>
        <div class="mt-n4 mb-2 text-md-body-1">Potential matching customers</div>
        <v-responsive class="overflow-y-auto" max-height="500" min-height="200">
          <v-data-table
            ref="app-create-customer-dialog-matching-customers-table"
            class="ma-n2 pa-0 flex-fill row-pointer"
            item-key="id"
            style="max-height: 600px"
            mobile-breakpoint="900"
            :headers="customerSearchHeaders"
            :items="potentialCustomerMatches"
            hide-default-footer
          >
            <template v-slot:item.phonePrimary="{ item }">
              <span ref="app-create-customer-dialog-phone-primary">{{ formatPhoneNumber(item.phonePrimary) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="justify-center">
                <v-btn color="primary" ref="customer-create-job-btn" rounded :loading="getIsLoadingCreateJob" :disabled="getIsLoadingCreateJob" @click="createJob(item)">
                  Create Job
                  <v-icon class="ps-2">mdi-receipt-text-plus-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-responsive>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="app-create-customer-dialog-cancel-btn" color="primaryText" @click="closeDialog" :disabled="getIsLoadingCustomers" text rounded ripple>Cancel</v-btn>
      <v-btn
        class="pa-4"
        ref="app-create-customer-dialog-confirm-btn"
        color="primary"
        @click="handleConfirmationAction"
        :loading="getConfirmationButtonLoadingState"
        :disabled="getConfirmationButtonLoadingState"
        text
        rounded
        ripple
      >
        {{ getConfirmationButtonText }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { CustomerType } from "@/enums/CustomerTypes"
import { PreferredMethodOfContact } from "@/enums/PreferredMethodOfContacts"
import phoneNumberFormatter from "@/utils/PhoneNumberFormatter"
import { mapActions, mapState } from "pinia"
import { useMainStore } from "@/stores/Main"
import { useCustomerStore } from "@/stores/Customer"
import { useSnackbarStore } from "@/stores/Snackbar"
import junkApi from "@/api/Junk"
import { createCustomerDto, customerSearchDto } from "@/api/dtos/JunkDtos"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { errorSnackbar } from "@/utils/SnackbarBuilder"

export default {
  name: "CreateCustomerDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedFranchise: {},
      firstName: "",
      lastName: "",
      businessName: "",
      phonePrimary: "",
      phonePrimaryExtension: "",
      phoneSecondary: "",
      phoneSecondaryExtension: "",
      email: "",
      isEmailOptedOut: false,
      selectedCustomerType: CustomerType.HOME.id,
      preferredMethodOfContactId: 1,
      isLoadingPotentialMatchingCustomers: false,
      isMatchesContentVisible: false,
      isTaxExempt: false,
      potentialCustomerMatches: [],
      isRequiredRules: [value => !!value || "Is required."],
      employerIdentificationNumber: "",
      preflightEmployerIdentificationNumber: "",
      isCharityPreflightValidated: false,
      einRule: [v => /^\d{2}-\d{7}$/.test((v ?? "").trim()) || "EIN must be in the format XX-XXXXXXX"],
      emailRules: [v => /.+@.+\..+/.test((v ?? "").trim()) || "E-mail must be valid"],
      preferredMethodOfContactRules: [
        value => !(value === PreferredMethodOfContact.EMAIL.id && (this.email || "").length <= 0) || "Please provide an email address or select a different method of contact"
      ],
      selectedFranchiseRule: [value => Object.keys(value).length !== 0 || "Is required."],
      customerSearchHeaders: [
        { text: "Customer Name", align: "start", value: "primaryContactName" },
        { text: "Business Name", align: "start", value: "businessName" },
        { text: "Phone Number", align: "start", value: "phonePrimary" },
        { text: "Email Address", align: "start", value: "email" },
        { text: "Actions", align: "center", value: "actions", width: "5%" }
      ]
    }
  },
  methods: {
    ...mapActions(useMainStore, ["fetchActiveFranchisesForUserByOperatingUnitIds", "validateEmployerIdentificationNumber", "getFranchiseEmailByFranchiseId"]),
    ...mapActions(useSnackbarStore, ["addSnackbar"]),
    initProperties() {
      this.firstName = this.getSearchParamFirstName
      this.lastName = this.getSearchParamLastName
      this.phonePrimary = this.getSearchParamPhone
      this.email = this.getSearchParamEmail
      if (this.getSearchParamBusinessName.length > 0) {
        this.businessName = this.getSearchParamBusinessName
        this.selectedCustomerType = CustomerType.BUSINESS.id
      }
      if (this.getUserActiveFranchises.length === 1) {
        this.selectedFranchise = this.getUserActiveFranchises[0]
      }
    },
    formatEin(value) {
      let numericValue = value.replace(/\D/g, "") // Remove all non-numeric characters
      if (numericValue.length > 9) {
        numericValue = numericValue.slice(0, 9) // Ensure max length of 9 digits
      }
      if (numericValue.length >= 2) {
        this.employerIdentificationNumber = numericValue.slice(0, 2) + "-" + numericValue.slice(2)
      } else {
        this.employerIdentificationNumber = numericValue
      }
    },
    updateSelectedFranchise(franchiseObj) {
      this.selectedFranchise = franchiseObj !== null && franchiseObj !== undefined ? Object.assign({}, franchiseObj) : Object.assign({}, {})
      if (this.isEmailOptedOut) {
        this.setEmailToSelectedFranchise(franchiseObj?.franchiseId)
      }
    },
    setEmailToSelectedFranchise(franchiseId) {
      let franchiseEmailAddress = this.getFranchiseEmailByFranchiseId(franchiseId)
      this.email = franchiseEmailAddress !== undefined ? franchiseEmailAddress : ""
    },
    setIsEmailOptedOut(isOptedOut) {
      this.isEmailOptedOut = isOptedOut
      if (isOptedOut) {
        this.setEmailToSelectedFranchise(this.selectedFranchise.franchiseId)
      } else {
        this.email = ""
      }
    },
    async handleConfirmationAction() {
      if (this.isMatchesContentVisible) {
        await this.save()
      } else {
        await this.fetchPotentialMatchingCustomers()
      }
    },
    async save() {
      let dto = createCustomerDto(
        this.selectedCustomerType === CustomerType.BUSINESS.id ? this.businessName?.trim() : null,
        this.selectedCustomerType,
        this.preferredMethodOfContactId,
        this.selectedFranchise.franchiseId,
        this.firstName,
        this.lastName,
        this.phonePrimary,
        this.phonePrimaryExtension,
        this.phoneSecondary,
        this.phoneSecondaryExtension,
        this.isEmailOptedOut ? this.selectedFranchise.franchiseEmail : this.email,
        this.$msal.getCurrentUserId(),
        this.selectedCustomerType === CustomerType.BUSINESS.id ? this.isTaxExempt : false,
        todayAsDate(),
        -1,
        this.isEmailOptedOut,
        this.employerIdentificationNumber
      )

      this.$emit("confirm", { createCustomerDto: dto, selectedOperatingUnitId: this.selectedFranchise.operatingUnitId })
    },
    createJob(item) {
      this.$emit("createJob", { customerId: item.id, franchiseId: this.selectedFranchise.franchiseId })
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    async fetchActiveFranchisesForUser() {
      if (this.getUserActiveFranchises?.length === 0) await this.fetchActiveFranchisesForUserByOperatingUnitIds()
    },
    async fetchPotentialMatchingCustomers() {
      if (this.$refs["app-create-customer-dialog-form"].validate()) {
        this.setIsLoadingPotentialMatchingCustomers(true)
        const dto = customerSearchDto(this.firstName, this.lastName, this.phonePrimary, this.email, this.businessName, null, 1, 25, this.getUserOperatingUnitIds, 10)

        await junkApi
          .fetchCustomers(dto)
          .then(data => {
            this.potentialCustomerMatches = data.items
            this.isMatchesContentVisible = data.items.length > 0
            let noResultsFound = data.items.length <= 0
            if (noResultsFound) {
              this.save()
            }
          })
          .catch(error => {
            this.addSnackbar(errorSnackbar("An Error Occurred Fetching Customers. Please try, again."))
            return Promise.reject(error)
          })
          .finally(() => this.setIsLoadingPotentialMatchingCustomers(false))
      }
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumberFormatter.formatPhoneNumber(phoneNumber)
    },
    setIsLoadingPotentialMatchingCustomers(isLoading) {
      this.isLoadingPotentialMatchingCustomers = isLoading
    },
    async validateEinWrapper() {
      this.preflightEmployerIdentificationNumber = this.employerIdentificationNumber
      await this.validateEmployerIdentificationNumber(this.employerIdentificationNumber).then(isCharity => {
        this.isCharityPreflightValidated = isCharity
        this.isTaxExempt = isCharity
      })
    }
  },
  computed: {
    ...mapState(useMainStore, [
      "getCustomerTypes",
      "getPreferredMethodOfContacts",
      "getUserActiveFranchises",
      "getUserOperatingUnitIds",
      "getIsLoadingValidateEin"
    ]),
    ...mapState(useCustomerStore, [
      "getIsLoadingCreateJob",
      "getIsLoadingCustomers",
      "getSearchParamFirstName",
      "getSearchParamLastName",
      "getSearchParamPhone",
      "getSearchParamEmail",
      "getSearchParamBusinessName"
    ]),
    isCharity() {
      return this.preflightEmployerIdentificationNumber !== this.employerIdentificationNumber ? false : this.isCharityPreflightValidated
    },
    charityIconColor() {
      return this.isCharity ? "primary" : "background"
    },
    getMaxDialogWidth() {
      return this.isMatchesContentVisible ? "1000px" : "650px"
    },
    getConfirmationButtonText() {
      return this.isMatchesContentVisible ? "Create New Customer" : "Confirm"
    },
    getConfirmationButtonLoadingState() {
      return this.isLoadingPotentialMatchingCustomers || this.getIsLoadingCustomers
    },
    isBusinessesSelected() {
      return this.selectedCustomerType === CustomerType.BUSINESS.id
    },
    franchiseEmailUsedMessage() {
      return "* By selecting <em>Refused to Provide</em>, the franchise's email address will be stored for this customer instead. The franchise will be responsible for passing along any communications that would've been emailed to the customer."
    },
    isCharityPreflightButtonDisabled() {
      return this.getIsLoadingValidateEin || !/^\d{2}-\d{7}$/.test(this.employerIdentificationNumber)
    },
    isTaxExemptDisabled() {
      return !!this.employerIdentificationNumber || this.getIsLoadingValidateEin
    },
    getPreferredMethodOfContactsFiltered() {
      let preferredMethodOfContacts = JSON.parse(JSON.stringify(this.getPreferredMethodOfContacts))
      preferredMethodOfContacts = preferredMethodOfContacts.filter(contactMethod => contactMethod.id !== PreferredMethodOfContact.TEXT.id)

      if (this.isEmailOptedOut) {
        preferredMethodOfContacts = preferredMethodOfContacts.filter(contactMethod => contactMethod.id !== PreferredMethodOfContact.EMAIL.id)
      }
      return preferredMethodOfContacts
    }
  },
  async created() {
    await this.fetchActiveFranchisesForUser()
    this.initProperties()
  }
}
</script>
