<template>
  <base-dialog ref="administrator-create-specialty-item-dialog-root" title="Create Add-On" :is-dialog-visible="isDialogVisible" dialog-image="mdi-view-grid-plus-outline">
    <template class="flex-fill" v-slot:content>
      <v-form class="ma-0 pa-0" ref="administrator-create-specialty-item-dialog-form" v-model="isFormValid">
        <v-layout row>
          <v-text-field
            ref="administrator-create-specialty-item-dialog-name-input"
            label="Add-On Name *"
            hint="This will be displayed in the franchise settings and job details screens."
            maxlength="25"
            counter="25"
            v-model.trim="name"
            :persistent-hint="true"
            :rules="isRequiredRules"
            @focus="selectAllContent"
            @input="toUpperCase"
            outlined
          />
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="administrator-create-specialty-item-dialog-cancel-button" color="primaryText" @click="closeDialog" text rounded ripple>
        Cancel
      </v-btn>
      <v-btn
        ref="administrator-create-specialty-item-dialog-create-button"
        class="pa-4"
        color="primary"
        :loading="isLoading"
        :disabled="isCreateDisabled"
        @click="handleCreate"
        text
        rounded
        ripple
      >
        Create
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { useAdministratorStore } from "@/stores/Administrator"
import { mapActions } from "pinia"
import { createAdministratorSpecialtyItemDto } from "@/api/dtos/JunkDtos"

export default {
  name: "AdministratorCreateSpecialtyItemDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: "",
      isRequiredRules: [value => (!!value && !!value.trim()) || "Name is required."],
      isFormValid: false
    }
  },
  computed: {
    isCreateDisabled() {
      return !this.isFormValid || this.isLoading
    }
  },
  methods: {
    ...mapActions(useAdministratorStore, ["createSpecialtyItem"]),
    selectAllContent(event) {
      event.target.select()
    },
    toUpperCase() {
      this.name = this.name.toUpperCase()
    },
    async handleCreate() {
      if (this.isFormValid) {
        const createItemDto = createAdministratorSpecialtyItemDto(this.name)
        await this.createSpecialtyItem(createItemDto).then(() => {
          this.closeDialog()
        })
      }
    },
    closeDialog() {
      this.$emit("close-dialog")
    }
  }
}
</script>
