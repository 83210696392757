import axios from "axios"
import getEnv from "@/utils/Env"
import { app } from "../../appsettings.json"
import apiUtils from "@/api/Utils"
import { useMainStore } from "@/stores/Main"

const client = axios.create({
  baseURL: getEnv("VUE_APP_API_URL") || app.VUE_APP_API_URL,
  timeout: 30000,
  json: true
})

export default {
  async execute(method, resource, dataParam, queryParams, timeout) {
    return client({
      method,
      url: resource,
      data: dataParam,
      params: queryParams,
      timeout: timeout,
      headers: {
        Authorization: await apiUtils.authHeader(),
        "User-Time-Zone-Id": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "Access-Control-Allow-Origin": "*",
        "User-Operating-Units": useMainStore().getUserOperatingUnitIds
      }
    }).then(req => {
      return req.data
    })
  },
  async fetchOperatingUnitSlotAllocation(operatingUnitId) {
    return await this.execute("get", `/api/v1/report/operating-unit-allocation-totals?operatingUnitId=${operatingUnitId}`)
  },
  async fetchRevenueForTruckAndDriver(operatingUnitId) {
    return await this.execute("get", `/api/v1/report/junk-revenue?operatingUnitId=${operatingUnitId}`)
  },
  async fetchScheduledConversionReport(operatingUnitId) {
    return await this.execute("get", `/api/v1/report/scheduled-conversion?operatingUnitId=${operatingUnitId}`)
  },
  async fetchClosedConversionReport(operatingUnitId) {
    return await this.execute("get", `/api/v1/report/closed-conversion?operatingUnitId=${operatingUnitId}`)
  },
  async fetchOperatingUnitRevenueReport(operatingUnitId) {
    return await this.execute("get", `/api/v1/report/operating-unit-revenue?operatingUnitId=${operatingUnitId}`)
  },
  async addAddress(customerId, validatedAddress) {
    return await this.execute("post", `api/v1/customer/${customerId}/address`, validatedAddress)
  },
  async deleteCustomerAddress(customerId, addressId) {
    return await this.execute("delete", `/api/v1/customer/${customerId}/address/${addressId}`)
  },
  async updateCustomerAddress(putCustomerAddressDto) {
    return await this.execute("put", "/api/v1/customer/address", putCustomerAddressDto)
  },
  async updateCustomerAddressUserValidated(putCustomerAddressUserValidatedDto) {
    return await this.execute("put", "/api/v1/customer/address/user-validated", putCustomerAddressUserValidatedDto)
  },
  async fetchFranchiseBusinessHours(franchiseId) {
    return await this.execute("get", `/api/v1/franchise-business-hours?franchiseId=${franchiseId}`)
  },
  async updateFranchiseBusinessHour(dto) {
    return await this.execute("put", "/api/v1/franchise-business-hour", dto)
  },
  async updateFranchiseSpecialHour(dto) {
    return await this.execute("put", "/api/v1/franchise-special-hour", dto)
  },
  async createFranchiseTax(createFranchiseTaxDto) {
    return await this.execute("post", "/api/v1/franchise-tax", createFranchiseTaxDto)
  },
  async putEndDateFranchiseTax(putFranchiseTaxEndDateDto) {
    return await this.execute("put", "/api/v1/franchise-tax/end-date", putFranchiseTaxEndDateDto)
  },
  async addFranchiseTaxAssociation(putFranchiseTaxEndDateDto) {
    return await this.execute("post", "/api/v1/franchise-tax/associate", putFranchiseTaxEndDateDto)
  },
  async fetchFranchiseTaxesByOperatingUnitId(operatingUnitId) {
    return await this.execute("get", `/api/v1/franchise-tax?operatingUnitId=${operatingUnitId}`)
  },
  async createFranchiseSpecialHour(createFranchiseSpecialHourDto) {
    return await this.execute("post", "/api/v1/franchise-special-hour", createFranchiseSpecialHourDto)
  },
  async createJunkJob(createJobDto) {
    return await this.execute("post", "/api/v1/job", createJobDto)
  },
  async createJunkJobNote(createJobNoteDto) {
    return await this.execute("post", "/api/v1/job/note", createJobNoteDto)
  },
  async softDeleteJunkJobNote(jobNoteId) {
    return await this.execute("delete", `/api/v1/job/note/${jobNoteId}`)
  },
  async createJunkJobEmployees(jobId, createJobEmployeeDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/employees`, createJobEmployeeDto)
  },
  async createJunkJobTrucks(jobId, createJunkJobTruckDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/job-trucks`, createJunkJobTruckDto)
  },
  async updateJunkJobTruckDownTime(updateJunkJobTruckDownTimeDto) {
    return await this.execute("put", `/api/v1/job-truck/down-time`, updateJunkJobTruckDownTimeDto)
  },
  async updateJunkJobEmployeeIsDriver(jobId, putJobEmployeeIsDriverDto) {
    return await this.execute("put", `/api/v1/job-employee/driver`, putJobEmployeeIsDriverDto)
  },
  async updateJunkJobEmployeeTip(jobId, jobEmployeeId, putJunkJobEmployeeTipDto) {
    return await this.execute("put", `/api/v1/job-employee/${jobEmployeeId}/tip`, putJunkJobEmployeeTipDto)
  },
  async addEmployeeDistributedTipsByJobId(jobId, putEmployeeTipsDto) {
    return await this.execute("put", `/api/v1/job-employee/${jobId}/add-employee-tips`, putEmployeeTipsDto)
  },
  async updateJunkJobTruckStartDateTime(putJunkJobTruckStartDateTimeDto) {
    return await this.execute("put", `/api/v1/job-truck/start-time`, putJunkJobTruckStartDateTimeDto)
  },
  async updateJunkJobTruckEndDateTime(putJunkJobTruckEndDateTimeDto) {
    return await this.execute("put", `/api/v1/job-truck/end-time`, putJunkJobTruckEndDateTimeDto)
  },
  async updateWeeklyTruckAllocation(junkTruckWeeklyAllocationDto) {
    return await this.execute("put", `/api/v1/junk-truck/weekly-allocation`, junkTruckWeeklyAllocationDto)
  },
  async deleteJunkJobTruck(jobTruckId) {
    return await this.execute("delete", `/api/v1/job-truck/${jobTruckId}`)
  },
  async deleteJunkJobEmployee(jobId, jobEmployeeId) {
    return await this.execute("delete", `/api/v1/job-employee/${jobEmployeeId}`)
  },
  async deleteFranchiseSpecialHour(franchiseSpecialHourId) {
    return await this.execute("delete", `/api/v1/franchise-special-hour/${franchiseSpecialHourId}`)
  },
  async updateGlobalTimes(updateTrucksAndEmployeeGlobalTimesDto) {
    return await this.execute("put", `/api/v1/job/global-times`, updateTrucksAndEmployeeGlobalTimesDto)
  },
  async fetchAbandonmentReasons() {
    return await this.execute("get", "/api/v1/abandonment-reasons")
  },
  async fetchActiveFranchiseCapacitiesByFranchiseIdAndDate(franchiseId, date) {
    return await this.execute("get", `/api/v1/franchise/${franchiseId}/franchise-capacities/active?date=${date}`)
  },
  async fetchFranchiseCapacitiesByFranchiseId(franchiseId) {
    return await this.execute("get", `/api/v1/franchise/${franchiseId}/franchise-capacities`)
  },
  async fetchTimeSlots() {
    return await this.execute("get", "/api/v1/time-slots")
  },
  async fetchJunkJobByJobId(jobId) {
    return await this.execute("get", `/api/v1/job/${jobId}`)
  },
  async fetchScheduleSlotAllocations(getSlotAllocationsDto) {
    return await this.execute("get", `/api/v1/schedule/slot-allocations?franchiseId=${getSlotAllocationsDto.franchiseId}&from=${getSlotAllocationsDto.from}&to=${getSlotAllocationsDto.to}`)
  },
  async fetchJunkJobStatuses() {
    return await this.execute("get", "/api/v1/job-statuses")
  },
  async fetchJunkJobNotes(jobId) {
    return await this.execute("get", `/api/v1/job/${jobId}/notes`)
  },
  async fetchJunkJobAddresses(jobId) {
    return await this.execute("get", `/api/v1/job/${jobId}/job-addresses`)
  },
  async fetchJunkJobEmployees(jobId) {
    return await this.execute("get", `/api/v1/job/${jobId}/employees`)
  },
  async fetchJunkJobTrucks(jobId) {
    return await this.execute("get", `/api/v1/job/${jobId}/trucks`)
  },
  async updateJunkJobStartOrReturnAddress(jobAddressId, addressId) {
    return await this.execute("put", `/api/v1/job-address/${jobAddressId}/address/${addressId}`)
  },
  async updateJunkJobAddressStopOrder(jobAddressId, desiredStopOrder) {
    return await this.execute("put", `/api/v1/job-address/${jobAddressId}/stop-order/${desiredStopOrder}`)
  },
  async deleteJunkJobAddress(jobAddressId) {
    return await this.execute("delete", `/api/v1/job-address/${jobAddressId}`)
  },
  async addJunkJobAddresses(jobId, addressIds) {
    const addressParams = await apiUtils.generateQueryParams("addressIds", addressIds)
    return await this.execute("post", `/api/v1/job/${jobId}/job-addresses`, null, addressParams)
  },
  async updateJunkJobPrices(jobPriceDto) {
    return await this.execute("put", "/api/v1/job/prices", jobPriceDto)
  },
  async updateJunkJobIsSingleItem(singleItemDto){
    return await this.execute("put", `/api/v1/job/single-item`, singleItemDto)
  },
  async updateJunkJobScheduledDate(jobScheduledDateDto) {
    return await this.execute("put", "/api/v1/job/scheduled-date", jobScheduledDateDto)
  },
  async updateJunkJobSelectedFranchise(putJunkJobDto) {
    return await this.execute("put", "/api/v1/job/franchise", putJunkJobDto)
  },
  async updateJunkJobSelectedFranchiseSatelliteOffice(putJunkJobSatelliteOfficeDto) {
    return await this.execute("put", "/api/v1/job/franchise-satellite-office", putJunkJobSatelliteOfficeDto)
  },
  async upsertFranchiseCapacity(franchiseCapacity) {
    return await this.execute("post", "/api/v1/franchise-capacities", franchiseCapacity)
  },
  async updateJunkJobPreferredTimeSlot(putJunkJobPreferredTimeSlotDto) {
    return await this.execute("put", "/api/v1/job/preferred-time-slot", putJunkJobPreferredTimeSlotDto)
  },
  async fetchJobCompletionStatuses(jobId) {
    return await this.execute("get", `/api/v1/job/${jobId}/job-completion-statuses`)
  },
  async fetchCancellationReasons() {
    return await this.execute("get", "/api/v1/cancellation-reasons")
  },
  async abandonJunkJob(jobId, abandonJobDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/abandon`, abandonJobDto)
  },
  async cancelJunkJob(jobId, cancelJobDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/cancel`, cancelJobDto)
  },
  async scheduleJunkJob(jobId, scheduleJobDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/schedule-job`, scheduleJobDto)
  },
  async claimJunkJob(jobId, claimJobDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/claim`, claimJobDto)
  },
  async disclaimJunkJob(jobId, disclaimJobDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/disclaim`, disclaimJobDto)
  },
  async createJobEmployeeIndirectLabor(jobId, createIndirectLaborDtos) { // todo: remove jobId from being passed at all call sites
    return await this.execute("post", `/api/v1/job-employees/indirect-labor`, createIndirectLaborDtos)
  },
  async closeJunkJob(jobId, closeJobDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/close`, closeJobDto)
  },
  async uncompleteJunkJob(uncompleteJobDto) {
    return await this.execute("put", "/api/v1/job/revert-to-claimed", uncompleteJobDto)
  },
  async completeJunkJob(jobId, completeJobDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/complete`, completeJobDto)
  },
  async fetchJobManagementData(operatingUnitId, statuses, fromDate, toDate, pageNumber, pageSize, includeOnlyCustomerSelfScheduled) {
    const statusParams = await apiUtils.generateQueryParams("JobStatusIds", statuses)
    return await this.execute(
      "get",
      `/api/v1/jobs?OperatingUnitId=${operatingUnitId}&From=${fromDate}&To=${toDate}&Page=${pageNumber}&PageSize=${pageSize}&IncludeOnlyCustomerSelfScheduled=${includeOnlyCustomerSelfScheduled}`,
      null,
      statusParams
    )
  },
  async fetchAllQueuedJobsByScheduledDateAndOperatingUnitId(date, operatingUnitId) {
    return await this.execute("get", `/api/v1/queue/?Date=${date}&OperatingUnitID=${operatingUnitId}`)
  },
  async fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits(operatingUnitIds) {
    let params = await apiUtils.generateQueryParams("operatingUnitIds", operatingUnitIds)
    return await this.execute("get", "/api/v1/franchise/active", null, params)
  },
  async fetchActiveFranchisesByOperatingUnitIds(operatingUnitIds) {
    let operatingUnitParams = await apiUtils.generateQueryParams("operatingUnitIds", operatingUnitIds)
    return await this.execute("get", `/api/v1/franchise/operating-units-franchises`, null, operatingUnitParams)
  },
  async fetchActiveFranchiseTaxesByFranchiseIdAndDate(franchiseId, betweenDate) {
    return await this.execute("get", `api/v1/franchise-tax/${franchiseId}/${betweenDate}`)
  },
  async updateJobSelectedFranchiseTax(putJobSelectedFranchiseTaxDto) {
    return await this.execute("put", "/api/v1/job/franchise-tax", putJobSelectedFranchiseTaxDto)
  },
  async fetchActiveFranchisesInOperatingUnit(operatingUnitId) {
    return await this.execute("get", `api/v1/operating-unit/${operatingUnitId}/active-franchises`)
  },
  async fetchCustomerContactsAndAddresses(customerId, operatingUnitIds) {
    let params = await apiUtils.generateQueryParams("userOperatingUnitIds", operatingUnitIds)
    return await this.execute("get", `/api/v1/customer/${customerId}`, null, params)
  },
  async fetchCustomerAddresses(customerId) {
    return await this.execute("get", `/api/v1/customer/${customerId}/addresses`)
  },
  async createCustomer(createCustomerDto) {
    return await this.execute("post", `/api/v1/customer`, createCustomerDto)
  },
  async updateCustomerDetails(putCustomerDetailsDto) {
    return await this.execute("put", "/api/v1/customer", putCustomerDetailsDto)
  },
  async createContact(createContactDto) {
    return await this.execute("post", `/api/v1/contact`, createContactDto)
  },
  async editContact(editContactDto, operatingUnitIds) {
    let params = await apiUtils.generateQueryParams("userOperatingUnitIds", operatingUnitIds)
    return await this.execute("put", `/api/v1/contact`, editContactDto, params)
  },
  async deleteContact(id) {
    return await this.execute("delete", `/api/v1/contact?id=${id}`)
  },
  async fetchCustomers(searchDto) {
    return await this.execute("post", `/api/v1/customer/search`, searchDto)
  },
  async createJunkTruck(operatingUnitId, junkTruckDto) {
    return await this.execute("post", `/api/v1/operating-unit/${operatingUnitId}/junk-trucks`, junkTruckDto)
  },
  async fetchJunkTrucksByOperatingUnitIds(operatingUnitIds) {
    let params = await apiUtils.generateQueryParams("operatingUnitIds", operatingUnitIds)
    return await this.execute("get", "/api/v1/junk-trucks", null, params)
  },
  async updateJunkTruck(putJunkTruckDto) {
    return await this.execute("put", "/api/v1/junk-trucks", putJunkTruckDto)
  },
  async fetchVehicleFuelTypes() {
    return await this.execute("get", `/api/v1/fuel-types`)
  },
  async fetchUnavailableTrucksByOperatingUnitIds(operatingUnitIds) {
    let params = await apiUtils.generateQueryParams("operatingUnitIds", operatingUnitIds)
    return await this.execute("get", `/api/v1/junk-trucks/unavailabilities`, null, params)
  },
  async createTruckUnavailability(postJunkTruckUnavailabilityDto) {
    return await this.execute("post", "/api/v1/junk-trucks/unavailabilities", postJunkTruckUnavailabilityDto)
  },
  async updateTruckUnavailability(putJunkTruckUnavailabilityDto) {
    return await this.execute("put", "/api/v1/junk-trucks/unavailabilities", putJunkTruckUnavailabilityDto)
  },
  async deleteTruckUnavailabilityById(id) {
    return await this.execute("delete", `/api/v1/junk-trucks/unavailabilities/${id}`)
  },
  async fetchActiveJunkTrucksInOperatingUnitByDate(date, operatingUnitId) {
    return await this.execute("get", `/api/v1/operating-unit/${operatingUnitId}/active-junk-trucks?date=${date}`)
  },
  async fetchAllOperatingUnitFranchisesAndSatellites() {
    return await this.execute("get", "/api/v1/franchise/operating-units-franchises-and-satellites")
  },
  async updateOperatingUnit(putOperatingUnitDto) {
    return await this.execute("put", "/api/v1/operating-unit", putOperatingUnitDto)
  },
  async updateFranchiseDetails(putFranchiseDetailsDto) {
    return await this.execute("put", "/api/v1/franchise", putFranchiseDetailsDto, null, 120000)
  },
  async updateSatelliteOfficeDetails(putSatelliteOfficeDetailsDto) {
    return await this.execute("put", "/api/v1/satellite-office", putSatelliteOfficeDetailsDto)
  },
  async createOperatingUnit(createOperatingUnitDto) {
    return await this.execute("post", "/api/v1/operating-unit", createOperatingUnitDto)
  },
  async enrollFranchise(enrollFranchiseDto) {
    return await this.execute("post", "/api/v1/franchise", enrollFranchiseDto)
  },
  async createSatelliteOffice(createSatelliteOfficeDto) {
    return await this.execute("post", "/api/v1/satellite-office", createSatelliteOfficeDto)
  },
  async createEmployee(createEmployeeDto) {
    return await this.execute("post", "/api/v1/employee", createEmployeeDto)
  },
  async upsertEmployeeDetails(upsertEmployeeDetailsDto) {
    return await this.execute("put", "/api/v1/employee", upsertEmployeeDetailsDto)
  },
  async fetchFranchiseSpecialHoursByFranchiseId(franchiseId) {
    return await this.execute("get", `/api/v1/franchise-special-hours?franchiseId=${franchiseId}`)
  },
  async fetchFranchiseTipConfigurationByFranchiseId(franchiseId) {
    return await this.execute("get", `/api/v1/franchise-tip-configuration?franchiseId=${franchiseId}`)
  },
  async upsertFranchiseTipConfiguration(upsertTipConfigurationDto) {
    return await this.execute("post", "/api/v1/franchise-tip-configuration", upsertTipConfigurationDto)
  },
  async putFranchiseCustomerSelfBookingDistanceCutoff(putFranchiseCustomerSelfBookingCutoffDto) {
    return await this.execute("put", "/api/v1/franchise/self-booking-cutoff-distance", putFranchiseCustomerSelfBookingCutoffDto)
  },
  async fetchOperatingUnitAllocationGroups(operatingUnitId) {
    return await this.execute("get", `/api/v1/operating-unit/${operatingUnitId}/allocation-groups`)
  },
  async createOperatingUnitAllocationGroup(operatingUnitId, postOperatingUnitAllocationGroupDto) {
    return await this.execute("post", `/api/v1/operating-unit/${operatingUnitId}/allocation-group`, postOperatingUnitAllocationGroupDto)
  },
  async deleteOperatingUnitAllocationGroup(operatingUnitAllocationGroupId) {
    return await this.execute("delete", `/api/v1/operating-unit/allocation-groups/${operatingUnitAllocationGroupId}`)
  },
  async updateOperatingUnitGroupName(putOperatingUnitAllocationGroupDto) {
    return await this.execute("put", `/api/v1/operating-unit/operating-unit-allocation-group`, putOperatingUnitAllocationGroupDto)
  },
  async createFranchiseOperatingUnitAllocationGroup(createFranchiseOperatingUnitAllocationGroupAssociationDto) {
    return await this.execute("post", `/api/v1/operating-unit/franchise-operating-unit-allocation-group`, createFranchiseOperatingUnitAllocationGroupAssociationDto)
  },
  async deleteFranchiseOperatingUnitAllocationGroup(franchiseOperatingUnitAllocationGroupId) {
    return await this.execute("delete", `/api/v1/operating-unit/franchise-operating-unit-allocation-group/${franchiseOperatingUnitAllocationGroupId}`)
  },
  async createNationalDiscount(createNationalDiscountDto) {
    return await this.execute("post", `/api/v1/discount/national-discount`, createNationalDiscountDto)
  },
  async createOperatingUnitDiscount(createOperatingUnitDiscountDto) {
    return await this.execute("post", `/api/v1/discount/operating-unit-discount`, createOperatingUnitDiscountDto)
  },
  async createFranchiseElectedDiscountForWebsite(createElectedBrandWebsiteDiscountDto) {
    return await this.execute("post", `/api/v1/discount/franchise-elected-discount/website`, createElectedBrandWebsiteDiscountDto)
  },
  async fetchNationalDiscounts() {
    return await this.execute("get", `/api/v1/discount/national-discounts`)
  },
  async fetchOperatingUnitDiscounts(operatingUnitId) {
    return await this.execute("get", `/api/v1/discount/operating-unit-discounts/operating-unit/${operatingUnitId}`)
  },
  async fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId(operatingUnitId) {
    return await this.execute("get", `/api/v1/discount/national-discounts/operating-unit/${operatingUnitId}`)
  },
  async fetchFranchiseElectedDiscountsForWebsiteByFranchiseId(franchiseId) {
    return await this.execute("get", `/api/v1/discount/franchise-elected-discount/website/${franchiseId}`)
  },
  async updateNationalDiscount(updateNationalDiscountDto) {
    return await this.execute("put", `/api/v1/discount/national-discount`, updateNationalDiscountDto)
  },
  async updateOperatingUnitDiscount(updateOperatingUnitDiscountDto) {
    return await this.execute("put", `/api/v1/discount/operating-unit-discount`, updateOperatingUnitDiscountDto)
  },
  async updateOperatingUnitFranchiseElectedDiscount(updateFranchiseElectedDiscountDto) {
    return await this.execute("put", `/api/v1/discount/operating-unit-franchise-elected-discount`, updateFranchiseElectedDiscountDto)
  },
  async updateFranchiseElectedDiscountForWebsite(updateElectedBrandWebsiteDiscountDto) {
    return await this.execute("put", `/api/v1/discount/franchise-elected-discount/website`, updateElectedBrandWebsiteDiscountDto)
  },
  async addFranchisesToNationalDiscount(createNationalDiscountElectedFranchisesDto) {
    return await this.execute("post", `/api/v1/discount/national-discount/franchises`, createNationalDiscountElectedFranchisesDto)
  },
  async addFranchisesToOperatingUnitDiscount(createOperatingUnitDiscountElectedFranchisesDto) {
    return await this.execute("post", `/api/v1/discount/operating-unit-discount/franchises`, createOperatingUnitDiscountElectedFranchisesDto)
  },
  async deleteOperatingUnitFranchiseElectedDiscountByDiscountId(discountId) {
    return await this.execute("delete", `/api/v1/discount/operating-unit-discount/${discountId}`)
  },
  async deleteFranchiseElectedDiscountForWebsiteById(discountId) {
    return await this.execute("delete", `/api/v1/discount/franchise-elected-discount/website/${discountId}`)
  },
  async fetchDiscountsByJobId(jobId) {
    return await this.execute("get", `/api/v1/job/${jobId}/eligible-discounts`)
  },
  async applyDiscountToJob(putJobDiscountDto) {
    return await this.execute("put", `/api/v1/job/apply-discount`, putJobDiscountDto)
  },
  async removeDiscountFromJob(deleteJobDiscountDto) {
    return await this.execute("put", `/api/v1/job/revert-discount`, deleteJobDiscountDto)
  },
  async putLeadSource(putLeadSourceDto) {
    return await this.execute("post", `/api/v1/job/lead-source`, putLeadSourceDto)
  },
  async fetchLeadSources() {
    return await this.execute("get", `/api/v1/lead-sources`)
  },
  async fetchSelfScheduledJobs(operatingUnitId) {
    return await this.execute("get", `/api/v1/job/${operatingUnitId}/self-scheduled-jobs`)
  },
  async fetchCloseOutReminderJobs(operatingUnitId) {
    return await this.execute("get", `/api/v1/job/${operatingUnitId}/close-out-reminder`)
  },
  async fetchJobDataFixCustomerDetailsByJobNumber(jobNumber) {
    return await this.execute("get", `/api/v1/data-fix/job/${jobNumber}/customer-details`)
  },
  async fetchJobDataFixDetailsByJobId(jobId) {
    return await this.execute("get", `/api/v1/data-fix/job/${jobId}`)
  },
  async postJobDataFixPreview(postJobDataFixPreviewDto) {
    return await this.execute("post", "/api/v1/data-fix/preview", postJobDataFixPreviewDto)
  },
  async postJobDataFixApply(jobId, employeeId) {
    return await this.execute("post", `/api/v1/data-fix/job/${jobId}/employee/${employeeId}`)
  },
  async resendJobConfirmationEmail(jobId, resendConfirmationEmailDto) {
    return await this.execute("post", `/api/v1/job/${jobId}/resend-confirmation-email`, resendConfirmationEmailDto)
  },
  async validateEmployerIdentificationNumber(ein) {
    return await this.execute("get", `/api/v1/candid/validate-charity?ein=${ein}`)
  },
  async createJobLead(postJobLeadDto) {
    return await this.execute("post", `/api/v1/job-lead`, postJobLeadDto)
  },
  async createJobLeadByJob(postJobLeadDto) {
    return await this.execute("post", `/api/v1/job/job-lead`, postJobLeadDto)
  },
  async updateJobLead(putJobLeadDto) {
    return await this.execute("put", `/api/v1/job-lead`, putJobLeadDto)
  },
  async validateJobSlotAvailability(validateAllocationDto) {
    return await this.execute("post", "/api/v1/schedule/validate-availability", validateAllocationDto)
  },
  async putJobAmSlotCount(putJobSlotCountDto) {
    return await this.execute("put", `/api/v1/job/am-slot-count`, putJobSlotCountDto)
  },
  async putJobPmSlotCount(putJobSlotCountDto) {
    return await this.execute("put", `/api/v1/job/pm-slot-count`, putJobSlotCountDto)
  },
  async sendCustomerTextBookingConfirmation(postCustomerTextBookingConfirmationDto) {
    return await this.execute("post", `/api/v1/dialpad/booking-confirmation`, postCustomerTextBookingConfirmationDto)
  },
  async sendCustomerTextEta(postCustomerTextEtaDto) {
    return await this.execute("post", `/api/v1/dialpad/eta`, postCustomerTextEtaDto)
  },
  async sendCustomerTextJobStatement(postCustomerTextJobStatementDto) {
    return await this.execute("post", `/api/v1/dialpad/statement`, postCustomerTextJobStatementDto)
  },
  async fetchJobDesiredTimeFrame(jobId) {
    return await this.execute("get", `/api/v1/job/${jobId}/desired-time-frame`)
  },
  async upsertDesiredTimeFrame(upsertDesiredTimeFrameDto) {
    return await this.execute("put", `/api/v1/job/desired-time-frame`, upsertDesiredTimeFrameDto)
  },
  async deleteDesiredTimeFrame(desiredTimeFrameId) {
    return await this.execute("delete", `/api/v1/job/desired-time-frame/${desiredTimeFrameId}`)
  },
  async fetchNonAdEmployeesByOperatingUnitId(operatingUnitId) {
    return await this.execute("get", `/api/v1/employee/non-ad-employees/${operatingUnitId}`)
  },
  async createNonAdEmployee(createHelperEmployeeDto) {
    return await this.execute("post", "/api/v1/employee/non-ad-employee", createHelperEmployeeDto)
  },
  async updateNonAdEmployee(updateHelperEmployeeDto) {
    return await this.execute("put", "/api/v1/employee/non-ad-employee", updateHelperEmployeeDto)
  },
  async fetchNonAdEmployeesInOperatingUnitByOperatingUnitId(operatingUnitId) {
    return await this.execute("get", `/api/v1/employee/active-non-ad-employees/${operatingUnitId}`)
  },
  async isFranchiseActive(franchiseId, date) {
    return await this.execute("get", `/api/v1/franchise/${franchiseId}/is-active?date=${date}`)
  },
  async fetchAdministratorSpecialtyItems() {
    return await this.execute("get", "/api/v1/specialty-item/administrator-specialty-items")
  },
  async createAdministratorSpecialtyItem(createAdministratorSpecialtyItemDto) {
    return await this.execute("post", "/api/v1/specialty-item/administrator-specialty-item", createAdministratorSpecialtyItemDto)
  },
  async updateAdministratorSpecialtyItem(updateAdministratorSpecialtyItemDto) {
    return await this.execute("put", "/api/v1/specialty-item/administrator-specialty-item", updateAdministratorSpecialtyItemDto)
  },
  async fetchSettingsSpecialtyItemsByFranchiseIds() {
    return await this.execute("get", "/api/v1/specialty-item/settings-specialty-items")
  },
  async addFranchiseSpecialtyItem(addFranchiseSpecialtyItemDto) {
    return await this.execute("post", "/api/v1/specialty-item/franchise-specialty-item", addFranchiseSpecialtyItemDto)
  },
  async editFranchiseSpecialtyItem(editFranchiseSpecialtyItemDto) {
    return await this.execute("put", "/api/v1/specialty-item/franchise-specialty-item", editFranchiseSpecialtyItemDto)
  },
  async fetchJobAddOnsByJobId(franchiseId, jobId) {
    return await this.execute("get", `/api/v1/specialty-item/job-add-ons/${franchiseId}/${jobId}`)
  },
  async updateJobAddOns(pubJobAddOnsDto) {
    return await this.execute("put", "/api/v1/specialty-item/job-add-ons", pubJobAddOnsDto)
  },
  async updateJobTotalsWithAddOns(jobId) {
    return await this.execute("post", `/api/v1/specialty-item/job-add-ons/${jobId}`)
  },
  async validateFranchiseSpecialtyItems(jobId, franchiseId) {
    return await this.execute("get", `/api/v1/job/${jobId}/validate-franchise-specialty-items/franchise/${franchiseId}`)
  }
}
