<template>
  <base-dialog
    ref="settings-add-franchise-specialty-item-dialog-root"
    :title="`Add ${specialtyItem.name} to Franchises`"
    :is-dialog-visible="isDialogVisible"
    dialog-image="mdi-view-grid-plus-outline"
  >
    <template class="flex-fill" v-slot:content>
      <v-layout row>
        <v-data-table
          class="flex-fill"
          ref="settings-add-franchise-specialty-item-dialog-data-table"
          item-class="text-md-body-1"
          item-key="franchiseId"
          sort-by="franchiseNumberAndName"
          height="32vh"
          :headers="headers"
          :items="eligibleFranchises"
          :rules="isRequiredSelectorRules"
          fixed-header
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.isElected="{ item }">
            <v-simple-checkbox
              ref="settings-add-franchise-special-item-dialog-elected-checkbox"
              color="primary"
              :value="item.isElected"
              @input="updateSelectedFranchiseElected($event, item)"
            />
          </template>
          <template v-slot:item.price="{ item }">
            <v-col class="ma-0 pa-0">
              <v-text-field
                class="mt-4 mb-n2 flex-fill justify-center text-center text-no-wrap"
                ref="settings-add-franchise-specialty-item-elected-price-text-field"
                type="number"
                min="0"
                max="999.99"
                prepend-icon="mdi-currency-usd"
                v-if="item.isElected"
                :value="item.price"
                :rules="priceValidationRules"
                @change="updateSelectedFranchisePrice($event, item)"
                @focus="selectAllContent"
                single-line
                outlined
              />
              <div v-else />
            </v-col>
          </template>
        </v-data-table>
      </v-layout>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-add-franchise-specialty-item-dialog-cancel-button" color="primaryText" @click="closeDialog" text rounded ripple>Cancel </v-btn>
      <v-btn
        class="pa-4"
        ref="settings-add-franchise-specialty-item-dialog-confirm-button"
        color="primary"
        @click="confirm"
        :loading="getIsLoadingSpecialtyItems"
        :disabled="isConfirmDisabled"
        text
        rounded
        ripple
      >
        Confirm
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { addFranchiseSpecialtyItemDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsAddFranchiseSpecialtyItemDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    specialtyItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isFormValid: false,
      eligibleFranchises: [],
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "At least one franchise must be selected."],
      priceValidationRules: [value => value >= 0 || "Price cannot be negative"]
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getIsLoadingSpecialtyItems", "getFranchisesInOperatingUnit", "getSelectedFranchise", "getActiveSpecialtyItems"]),
    headers() {
      return [
        { text: "Elected", value: "isElected", sortable: false },
        { text: "Franchise", value: "franchiseNumberAndName" },
        { text: "Price", value: "price", align: "center", sortable: false }
      ]
    },
    getEligibleFranchisesForSpecialtyItem() {
      let electedFranchiseIds = this.specialtyItem.franchiseSpecialtyItems.map(fsi => fsi.franchiseId)
      return this.getFranchisesInOperatingUnit
        .filter(franchise => !electedFranchiseIds.includes(franchise.franchiseId))
        .map(franchise => {
          return {
            franchiseId: franchise.franchiseId,
            isElected: false,
            franchiseNumberAndName: franchise.franchiseNameAndNumber,
            price: 0
          }
        })
    },
    hasInvalidPricesForSpecialtyItems() {
      return this.eligibleFranchises.some(f => f.isElected && f.price < 0)
    },
    isFranchiseSelectionEmpty() {
      return this.eligibleFranchises.every(f => !f.isElected)
    },
    isConfirmDisabled() {
      return this.getIsLoadingSpecialtyItems || this.isFranchiseSelectionEmpty || this.hasInvalidPricesForSpecialtyItems
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["addFranchiseSpecialtyItem", "fetchSpecialtyItems"]),
    selectAllContent(event) {
      event.target.select()
    },
    updateSelectedFranchiseElected(event, item) {
      let idx = this.eligibleFranchises.findIndex(f => f.franchiseId === item.franchiseId)
      if (~idx) {
        let matchingRecord = this.eligibleFranchises[idx]
        matchingRecord.isElected = event
        matchingRecord.price = 0
        this.eligibleFranchises.splice(idx, 1, matchingRecord)
      }
    },
    updateSelectedFranchisePrice(event, item) {
      if (event === "") {
        event = 0
      }
      let idx = this.eligibleFranchises.findIndex(f => f.franchiseId === item.franchiseId)
      if (~idx) {
        let matchingRecord = this.eligibleFranchises[idx]
        matchingRecord.price = event
        this.eligibleFranchises.splice(idx, 1, matchingRecord)
      }
    },
    async confirm() {
      const electedFranchises = this.eligibleFranchises.filter(f => f.isElected)
      const addFranchiseSpecialtyItems = electedFranchises.map(f => {
        return {
          franchiseId: f.franchiseId,
          price: f.price
        }
      })
      const dto = addFranchiseSpecialtyItemDto(this.specialtyItem.administratorSpecialtyItemId, addFranchiseSpecialtyItems)
      await this.addFranchiseSpecialtyItem(dto).then(() => {
        this.fetchSpecialtyItems()
        this.closeDialog()
      })
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  created() {
    this.eligibleFranchises = this.getEligibleFranchisesForSpecialtyItem
  }
}
</script>
