<template>
  <v-card ref="job-truck-capacity-card-root" class="flex d-flex flex-column fill-height transition-ease-in-out" elevation="4" :loading="getIsLoadingFranchiseCapacities">
    <v-card-title ref="job-truck-capacity-card-title" class="headline">Truck Capacity</v-card-title>
    <v-card-text>
      <v-col>
        <v-form ref="truck-capacity-card-override-price-form">
          <v-text-field
              class="mt-0 me-1 mt-md-n11 mt-lg-n11 mt-xl-n11 float-none float-md-end float-lg-end float-xl-end"
              ref="job-truck-capacity-card-override-price-text-field"
              label="Base Price"
              prefix="$"
              type="number"
              :key="priceKey"
              @change="setOverridePrice"
              :value="getCurrentPriceFormatted"
              :disabled="isActionsDisabled"
              :loading="isLoadingFranchiseCapacitiesOrJob"
              :rules="priceValidationRules"
              :append-icon="getAppendIcon"
              @click:append="undoPriceOverride"
              @focus="selectAllContent"
              outlined

          />
          <div class="checkbox-wrapper">
            <v-checkbox
                class="justify-end mt-n3 mb-3"
                ref="job-truck-capacity-card-single-item-checkbox"
                label="Single Item"
                :value="getIsSingleItem"
                :disabled="isActionsDisabled"
                :loading="isLoadingFranchiseCapacitiesOrJob"
                :true-value="true"
                :false-value="false"
                @change="setIsSingleItem" />
          </div>
        </v-form>
      </v-col>
    </v-card-text>
    <v-card-actions class="mt-16 flex-fill">
      <v-col class="flex-fill mx-2 mx-sm-2 mt-8">
        <v-slider
          ref="job-truck-capacity-card-price-slider"
          :thumb-color="getThumbAndTrackColor"
          thumb-size="52"
          thumb-label="always"
          :track-fill-color="getThumbAndTrackColor"
          track-color="background"
          :readonly="isActionsDisabled"
          tick-size="4"
          :key="sliderKey"
          @change="setSelectedPriceSlider"
          :value="getSelectedIndex"
          min="0"
          step="1"
          :max="getSliderMaxIndex"
          :tick-labels="truckCapacitySliderTickLabels"
        >
          <template v-slot:thumb-label>
            <app-number-formatter class="font-weight-bold" :amount="getCurrentlySelectedCapacityRate" currencySign="accounting" numberFormatStyle="currency" />
          </template>
        </v-slider>
        <v-row class="pt-1">
          <v-col>
            <app-number-formatter class="px-3 px-sm-1 px-md-2 float-start" :amount="getMinimumSliderPrice" currencySign="accounting" numberFormatStyle="currency" />
          </v-col>
          <v-col>
            <app-number-formatter class="px-3 px-sm-1 px-md-2 float-end" :amount="getMaxSliderPrice" currencySign="accounting" numberFormatStyle="currency" />
          </v-col>
        </v-row>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import { putJobPriceDto, putJunkJobSingleItemDto } from "@/api/dtos/JunkDtos"
import { mapActions, mapState } from "pinia"
import { useJobStore } from "@/stores/Job";

export default {
  name: "JobTruckCapacityCard",
  components: { AppNumberFormatter },
  data() {
    return {
      sliderKey: 0,
      priceKey: 0,
      isSingleItemKey: 0,
      priceValidationRules: [
        value => !!value || "Price is required.",
        value => value >= 0 || "Price must be positive.",
        value => value <= 999999.99 || "Price must be less than or equal to $999,999.99."
      ]
    }
  },
  methods: {
    ...mapActions(useJobStore, ["updateJunkJobPrices", "updateJunkJobSingleItemStatus"]),
    async saveSelectedCapacityAndPrices(jobPriceDto) {
      if (this.$refs["truck-capacity-card-override-price-form"].validate()) {
        return await this.updateJunkJobPrices(jobPriceDto)
      }
    },
    updateSliderKeySelection() {
      this.sliderKey === 1 ? this.sliderKey = 0 : this.sliderKey = 1
    },
    updatePriceKeySelection() {
      this.priceKey === 1 ? this.priceKey = 0 : this.priceKey = 1
    },
    updateIsSingleItemKeySelection() {
      this.isSingleItemKey === 1 ? this.isSingleItemKey = 0 : this.isSingleItemKey = 1
    },
    async setOverridePrice(price) {
      const dto = putJobPriceDto(this.getCurrentJob.id, this.getCurrentJob.franchiseCapacityId, price, this.$msal.getCurrentUserId(), this.getCurrentJob.operatingUnitId, this.getCurrentJobScheduledDateOrToday)
      console.log("before catch block!")
      this.saveSelectedCapacityAndPrices(dto).catch(() => {
        console.log("catch block!")
        this.updatePriceKeySelection()
      })
    },
    async undoPriceOverride() {
      const dto = putJobPriceDto(this.getCurrentJob.id, this.getCurrentJob.franchiseCapacityId, this.getCurrentlySelectedCapacityRate, this.$msal.getCurrentUserId(), this.getCurrentJob.operatingUnitId, this.getCurrentJobScheduledDateOrToday)
      await this.saveSelectedCapacityAndPrices(dto)
    },
    async setSelectedPriceSlider(index) {
      const franchiseCapacityId = this.getActiveFranchiseCapacities[index].id
      const price = this.getActiveFranchiseCapacities[index].rate
      const dto = putJobPriceDto(this.getCurrentJob.id, franchiseCapacityId, price, this.$msal.getCurrentUserId(), this.getCurrentJob.operatingUnitId, this.getCurrentJobScheduledDateOrToday)

      this.saveSelectedCapacityAndPrices(dto).catch(() => {
        this.updateSliderKeySelection()
      })
    },
    async setIsSingleItem(event) {
      const singleItemDto = new putJunkJobSingleItemDto(this.getCurrentJob.id, event, this.$msal.getCurrentUserId())
      await this.updateJunkJobSingleItemStatus(singleItemDto).catch(() => {
        this.updateIsSingleItemKeySelection()
      })
    },
    selectAllContent(event) {
      event.target.select()
    }
  },
  computed: {
    ...mapState(useJobStore, [
      "getActiveFranchiseCapacities",
      "getCurrentJob",
      "getCurrentJobScheduledDateOrToday",
      "getIsLoadingJob",
      "getIsLoadingFranchiseCapacities",
      "getIsJobFieldsDisabled"
    ]),
    getThumbAndTrackColor() {
      return this.isActionsDisabled === true ? "secondary" : "primary"
    },
    truckCapacitySliderTickLabels() {
      return this.getActiveFranchiseCapacities?.map(afc => afc.capacityName) ?? []
    },
    getSliderMaxIndex() {
      return this.truckCapacitySliderTickLabels?.length > 0 ? this.truckCapacitySliderTickLabels?.length - 1 : 7
    },
    getSelectedIndex() {
      return this.getCurrentJob?.capacityId ? this.getCurrentJob.capacityId - 1 : 0
    },
    getCurrentlySelectedCapacityRate() {
      return this.getActiveFranchiseCapacities[this.getSelectedIndex]?.rate ?? 0
    },
    isSelectedPriceOverridden() {
      return parseFloat(this.getCurrentPriceFormatted) !== this.getCurrentlySelectedCapacityRate ?? false
    },
    getAppendIcon() {
      return this.isSelectedPriceOverridden ? "mdi-arrow-u-left-bottom" : "mdi-blank"
    },
    getCurrentPriceFormatted() {
      return this.getCurrentJob?.price ? formatDecimalValue(this.getCurrentJob?.price) : "0.00"
    },
    getIsSingleItem() {
      return this.getCurrentJob?.isSingleItem ?? false
    },
    isLoadingFranchiseCapacitiesOrJob() {
      return this.getIsLoadingFranchiseCapacities || this.getIsLoadingJob
    },
    isActionsDisabled() {
      return this.isLoadingFranchiseCapacitiesOrJob || this.getIsJobFieldsDisabled
    },
    getMinimumSliderPrice() {
      return this.getActiveFranchiseCapacities[0]?.rate ?? 0.0
    },
    getMaxSliderPrice() {
      return this.getActiveFranchiseCapacities[this.getActiveFranchiseCapacities.length - 1]?.rate ?? 0.0
    }
  }
}
</script>

<style>
.checkbox-wrapper {
  display: flex;
  justify-content: flex-start;
}
</style>
