<template>
  <v-card class="flex d-flex flex-column">
    <v-card-title class="headline">
      <v-row>
        <v-col cols="auto">
          <span class="me-1 word-wrap">Statements Not Sent</span>
          <base-tool-tip
            ref="dashboard-no-receipt-delivered-card-tooltip"
            class="ps-4 ms-4"
            dialog-title="Statements Not Sent"
            tooltip-icon="mdi-information-outline"
            tooltip-color="primary"
            icon-color="primary"
            :nudge-dialog="this.$vuetify.breakpoint.mobile ? '80' : '150'"
            :is-bottom-dialog="true"
          >
            <template v-slot:content>
              <div>These are generated statements for which the primary contact did not provide an email address and no other recipients were added.</div>
            </template>
          </base-tool-tip>
        </v-col>
        <v-col class="float-end">
          <span ref="dashboard-no-receipt-delivered-card-items-count-caption" class="float-end font-italic word-wrap">
            {{ getItemsCountCaptionText }}
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-data-table
          class="overflow-y-auto flex-fill"
          ref="dashboard-no-receipt-delivered-card-table"
          item-class="text-md-body-1"
          loading-text="🔎 Fetching Statements Not Sent 🔍"
          height="30vh"
          :headers="tableHeaders"
          :items="getReceiptsNotSent"
          :loading="getIsLoadingReceiptsNotSent"
          :sort-by="['scheduledDate']"
          :sort-desc="true"
          fixed-header
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.jobNumber="{ item }">
            <router-link ref="dashboard-no-receipt-delivered-card-link-router" class="text-md-body-1" :to="{ name: 'Job', params: { jobId: item.jobId } }">
              {{ item.jobNumber }}
            </router-link>
            <v-icon ref="dashboard-no-receipt-delivered-card-link-icon" class="ms-2" color="primary" @click="addNewJobTab(item)">
              mdi-pin-outline
            </v-icon>
          </template>
          <template v-slot:item.customerName="{ item }">
            <div ref="dashboard-no-receipt-delivered-card-name-slot">
              {{ getFullName(item.customerFirstName, item.customerLastName) }}
            </div>
          </template>
          <template v-slot:item.scheduledDate="{ item }">
            <div ref="dashboard-no-receipt-delivered-card-date-slot">
              {{ getFormattedDate(item.scheduledDate) }}
            </div>
          </template>
        </v-data-table>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseToolTip from "@/components/BaseToolTip"
import { useMainStore } from "@/stores/Main"
import { useDashboardStore } from "@/stores/Dashboard"
import { mapActions, mapState } from "pinia"
import { JobTab } from "@/models/JobTab"
import dayjs from "dayjs"

export default {
  name: "DashboardNoReceiptDeliveredCard",
  components: { BaseToolTip },
  methods: {
    ...mapActions(useMainStore, ["addJobTab"]),
    dayjs,
    async addNewJobTab(item) {
      let tab = new JobTab(item.jobId, item.jobNumber, item.customerId, item.customerLastName, item.businessName)
      await this.addJobTab(tab)
    },
    getFullName(firstName, lastName) {
      return `${firstName} ${lastName}`
    },
    getFormattedDate(date) {
      return this.dayjs(date).format("ddd, MMM D")
    }
  },
  computed: {
    ...mapState(useDashboardStore, ["getIsLoadingReceiptsNotSent", "getReceiptsNotSent"]),
    tableHeaders() {
      return [
        { text: "Job Number", align: "start", value: "jobNumber" },
        { text: "Customer Name", align: "start", value: "customerName" },
        { text: "Scheduled Date", align: "start", value: "scheduledDate" }
      ]
    },
    receiptsNotSentCount() {
      return this.getReceiptsNotSent?.length ?? 0
    },
    getItemsCountCaptionText() {
      return this.receiptsNotSentCount === 1 ? `${this.receiptsNotSentCount} Statement Not Sent` : `${this.receiptsNotSentCount} Statements Not Sent`
    }
  }
}
</script>
