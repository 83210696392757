<template>
  <v-card id="settings-trucks-card" class="elevation-4" min-height="225">
    <v-card-title id="settings-trucks-card-title-layout">
      <v-col class="ms-0 mb-4 me-0 mt-0 pa-0 align-self-start d-flex">
        <span id="settings-trucks-card-title-heading" class="headline text-no-wrap align-self-start d-flex pe-1">Trucks</span>
        <base-tool-tip
          class="float-start align-self-start text-start justify-start"
          v-if="isDisplayPastTrucksEnabled"
          id="settings-trucks-card-title-past-trucks-tool-tip"
          dialog-title="Grey Denotes Past Truck"
          tooltip-icon="mdi-information-outline"
          :is-bottom-dialog="true"
        ></base-tool-tip>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0 d-flex flex-fill ms-0 ms-sm-4 ms-md-8 ms-lg-12 ms-xl-6 col-lg-6 col-xl-5" cols="auto">
        <v-switch
          ref="settings-trucks-card-include-past-trucks-switch"
          class="ma-0 mx-2 pa-0 justify-end align-end float-end align-self-end word-wrap"
          :disabled="isDisplayPastTrucksToggleDisabled"
          v-model="isDisplayPastTrucksEnabled"
          @click="scrollToSettingsTrucksCard"
          label="Include Past Trucks"
        ></v-switch>
        <v-text-field
          id="settings-truck-search-field"
          class="ma-0 mx-4 ps-4 float-end d-flex align-self-end flex-fill"
          v-model="searchText"
          :disabled="isSearchTextFieldDisabled"
          clearable="clearable"
          clear-icon="mdi-trash-can-outline"
          append-icon="mdi-magnify"
          label="Search Trucks"
        />
      </v-col>
    </v-card-title>
    <v-card-subtitle class="word-wrap">
      Click the arrow to configure slots per day of week for a truck.
    </v-card-subtitle>
    <v-card-text id="settings-trucks-card-text" class="mt-n4">
      <v-responsive class="overflow-y-auto" max-height="800">
        <v-data-table
          id="settings-trucks-table"
          :headers="truckHeaders"
          :items="trucksInOperatingUnit"
          item-key="id"
          :loading="getJunkTrucksLoadingState"
          :item-class="itemRowBackground"
          sort-by="name"
          mobile-breakpoint="840"
          loading-text="🔎 Fetching Trucks 🔍"
          style="min-height: 120px"
          :custom-filter="trucksDataTableCustomFilter"
          :search="searchText"
          fixed-header
          hide-default-footer
          disable-pagination
          show-expand
          expand-icon="mdi-chevron-down"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td class="pa-0" :colspan="headers.length">
              <v-responsive class="overflow-y-auto" max-height="500">
                <v-data-table
                  ref="settings-trucks-card-dialog-days-of-week-table"
                  mobile-breakpoint="900"
                  :items="getWeeklyAllocationsByJunkTruckId(item.id)"
                  :headers="truckScheduleHeaders"
                  disable-sort
                  dense
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.amSlots="{ item }">
                    <v-text-field ref="settings-trucks-card-dialog-days-of-week-table-am-slots" v-if="isRowItemBeingEdited(item.junkTruckWeeklyAllocationId)" class="mt-4 mb-n2" min="0" :disabled="!isRowItemBeingEdited(item.junkTruckWeeklyAllocationId)" max="99" :value="editedRowItem.amSlots" outlined dense type="number" @change="setEditableRowItemAmSlots($event)" />
                    <v-text-field ref="settings-trucks-card-dialog-days-of-week-table-am-slots-read-only" v-else class="mt-4 mb-n2" min="0" disabled max="99" :value="item.amSlots" outlined dense type="number" />
                  </template>
                  <template v-slot:item.pmSlots="{ item }">
                    <v-text-field ref="settings-trucks-card-dialog-days-of-week-table-pm-slots" v-if="isRowItemBeingEdited(item.junkTruckWeeklyAllocationId)" class="mt-4 mb-n2" min="0" max="99" :disabled="!isRowItemBeingEdited(item.junkTruckWeeklyAllocationId)" :value="editedRowItem.pmSlots" outlined dense type="number" @change="setEditableRowItemPmSlots($event)" />
                    <v-text-field ref="settings-trucks-card-dialog-days-of-week-table-pm-slots-read-only" v-else class="mt-4 mb-n2" min="0" disabled max="99" :value="item.pmSlots" outlined dense type="number" />
                  </template>
                  <template v-slot:item.dispatchLocationId="{ item }">
                    <v-select ref="settings-trucks-card-dialog-days-of-week-table-dispatch-selector"
                      v-if="isRowItemBeingEdited(item.junkTruckWeeklyAllocationId)"
                      class="mt-4 mb-n2"
                      :disabled="!isRowItemBeingEdited(item.junkTruckWeeklyAllocationId)"
                      :value="editedRowItem.dispatchLocationId"
                      item-value="addressId"
                      :items="getFranchisesInOperatingUnit"
                      item-text="franchiseName"
                      outlined
                      dense
                      @change="onTruckDispatchLocationChanged($event)">
                    </v-select>
                    <v-select
                      ref="settings-trucks-card-dialog-days-of-week-table-dispatch-selector-read-only"
                      v-else
                      class="mt-4 mb-n2"
                      :disabled="!isRowItemBeingEdited(item.junkTruckWeeklyAllocationId)"
                      :value="item.dispatchLocationId"
                      item-value="addressId"
                      :items="getFranchisesInOperatingUnit"
                      item-text="franchiseName"
                      outlined
                      dense>
                    </v-select>
                  </template>
                  <template v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
                    <div class="justify-center" v-if="isRowItemBeingEdited(item.junkTruckWeeklyAllocationId)">
                      <v-btn color="primary" ref="settings-trucks-edit-weekly-allocation-save-item-btn" data-cy="settings-trucks-edit-weekly-allocation-save-item-btn" @click="saveEditableItem" icon>
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                      <v-btn color="red" ref="settings-trucks-edit-weekly-allocation-cancel-item-btn" data-cy="settings-trucks-edit-weekly-allocation-cancel-item-btn" @click="resetEditableItem" icon>
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </div>
                    <div v-else-if="isRowItemEditable(item.junkTruckId)" class="justify-center">
                      <v-btn color="primary" ref="settings-trucks-edit-weekly-allocation-edit-item-btn" data-cy="settings-trucks-edit-weekly-allocation-edit-item-btn" @click="editItem(item)" icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-responsive>
            </td>
          </template>

          <template v-slot:no-data>
            <v-col class="ma-0 pa-0 align-self-center d-flex">
              <app-not-found title="Ope! No available records :(" content="Looks like you don't have any records, please retry or refresh the page.">
                <template v-slot:actions>
                  <v-btn class="mt-4 primary justify-center align-self-center" ref="settings-trucks-card-fetch-btn" @click="callFetchJunkTrucksByOperatingUnit" ripple rounded large>Fetch Junk Trucks</v-btn>
                </template>
              </app-not-found>
            </v-col>
          </template>
          <template v-slot:item.name="{ item }">
            <div class="text-md-body-1 grey--text">{{ item.name }}</div>
          </template>
          <template v-slot:item.depreciationPerMile="{ item }">
            <v-text-field
              v-if="isItemBeingEdited(item.id)"
              class="mt-4 mb-n2 d-inline-flex justify-center text-center"
              ref="settingsTruckDepreciationEditableField"
              @change="setEditableItemDepreciationPerMile"
              :value="editedItem.depreciationPerMile"
              :rules="truckDepreciationValidationRules"
              validate-on-blur
              type="number"
              min="0"
              max="999.99"
              counter="6"
              prepend-inner-icon="mdi-currency-usd"
              dense
              single-line
              outlined
              clearable
            ></v-text-field>
            <app-number-formatter
              v-else
              class="text-md-body-1 grey--text"
              :amount="item.depreciationPerMile"
              currency-sign="accounting"
              number-format-style="currency"
            ></app-number-formatter>
          </template>
          <template v-slot:item.truckFuelTypeId="{ item }">
            <div style="width: 180px">
              <v-select
                v-if="isItemBeingEdited(item.id)"
                :items="getVehicleFuelTypes"
                v-model="editedItem.truckFuelTypeId"
                item-text="name"
                item-value="id"
                dense
                outlined
                flat
                hide-details
              ></v-select>
              <v-select
                v-else
                class="d-inline-flex text-center align-self-center justify-center align-content-center"
                :value="item.truckFuelTypeId"
                :items="getVehicleFuelTypes"
                item-text="name"
                item-value="id"
                background-color="transparent"
                append-icon="mdi-blank"
                disabled
                solo
                flat
                hide-details
              >
                <template v-slot:selection="{ item }">
                  <span class="d-inline-flex justify-center wrap flex-wrap flex" style="width: 100%;">
                    {{ item.name }}
                  </span>
                </template>
              </v-select>
            </div>
          </template>
          <template v-slot:item.beginDateTime="{ item }">
            <app-date-picker-field-selector
              v-if="isItemBeingEdited(item.id)"
              :date-picker-value="editedItem.beginDateTime"
              :minimum-date-value="minimumPickerDate"
              :maximum-date-value="editableItemBeginDateMaximumDate"
              text-field-format="MMMM D, YYYY"
              @updatedPickerValue="setEditableItemBeginDateTime($event)"
              :text-field-disabled="isEditableBeginDateTimeFieldDisabled(editedItem.beginDateTime)"
              :is-text-field-dense="true"
            ></app-date-picker-field-selector>
            <app-date-time-locale-formatter v-else class="text-md-body-1 grey--text" :date-time-value="item.beginDateTime"></app-date-time-locale-formatter>
          </template>
          <template v-slot:item.endDateTime="{ item }">
            <app-date-picker-field-selector
              v-if="isItemBeingEdited(item.id)"
              :date-picker-value="editedItem.endDateTime"
              :minimum-date-value="editableItemEndDateTimeMinimumValue"
              text-field-format="MMMM D, YYYY"
              @updatedPickerValue="setEditableItemEndDateTime($event)"
              :isTextFieldDense="true"
            ></app-date-picker-field-selector>
            <app-date-time-locale-formatter v-else class="text-md-body-1 grey--text" :date-time-value="item.endDateTime"></app-date-time-locale-formatter>
          </template>
          <template v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
            <div class="justify-center" v-if="isItemBeingEdited(item.id)">
              <v-btn color="primary" ref="settings-truck-edit-item-save-btn" @click="saveEditableTruckItem" icon>
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn color="red" ref="settings-truck-edit-item-cancel-btn" @click="resetEditableTruckItem" icon>
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </div>
            <div v-else-if="isItemEditable(item)" class="justify-center">
              <v-btn color="primary" ref="settings-truck-edit-item-edit-btn" @click="setEditTruckItem(item)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-responsive>
    </v-card-text>
  </v-card>
</template>

<script>
import AppDateTimeLocaleFormatter from "@/components/AppDateTimeLocaleFormatter"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import { formatAsDateOnly, todayAsDate, tomorrowAsDate } from "@/utils/DateTimeFormatters"
import dayjs from "dayjs"
import BaseToolTip from "@/components/BaseToolTip"
import AppNotFound from "@/components/AppNotFound"
import { useMainStore } from "@/stores/Main"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsTrucksCard",
  components: { AppNotFound, BaseToolTip, AppNumberFormatter, AppDatePickerFieldSelector, AppDateTimeLocaleFormatter },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editedItem: {},
      isDisplayPastTrucksEnabled: false,
      minimumPickerDate: tomorrowAsDate(),
      searchText: "",
      truckDepreciationValidationRules: [
        value => !!value || "Depreciation is required.",
        value => value > 0 || "Depreciation must be positive.",
        value => value <= 999.99 || "Depreciation must be less than or equal to $999.99."
      ],
      editedRowItem: {}
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["fetchJunkTrucksByOperatingUnitIds", "updateJunkTruck"]),
    setEditableRowItemAmSlots(value) {
      console.log("Setting am slots editable to = ", value)
      this.editedRowItem.amSlots = value
    },
    setEditableRowItemPmSlots(value) {
      console.log("Setting am slots editable to = ", value)
      this.editedRowItem.pmSlots = value
    },
    onTruckDispatchLocationChanged(addressId) {
      console.log("addressId = ", addressId)
      this.editedRowItem.dispatchLocationId = addressId
    },
    isRowItemBeingEdited(itemId) {
      return itemId === this.editedRowItem?.junkTruckWeeklyAllocationId
    },
    setEditableItemDepreciationPerMile(value) {
      this.editedItem.depreciationPerMile = formatDecimalValue(value)
    },
    async saveEditableItem() {
      this.$emit("save-weekly-truck-allocation", this.editedRowItem)
      await this.resetEditableItem()
    },
    async resetEditableItem() {
      this.editedRowItem = {}
      console.log("invoked reset editable item")
    },
    async editItem(item) {
      this.editedRowItem = Object.assign({}, item)
      console.log("invoked editItem item with ", item)
    },
    setEditableItemBeginDateTime(dateEvent) {
      this.editedItem.beginDateTime = dateEvent
    },
    setEditableItemEndDateTime(dateEvent) {
      this.editedItem.endDateTime = dateEvent
    },
    isItemEditable(item) {
      return (formatAsDateOnly(item.endDateTime) ?? todayAsDate()) >= todayAsDate()
    },
    isRowItemEditable(junkTruckId) {
      let truck = this.getAllTrucksInOperatingUnitSortedByName.find(c => c.id === junkTruckId)
      return this.isItemEditable(truck) ?? false
    },
    isEditableBeginDateTimeFieldDisabled(beginDateTime) {
      return formatAsDateOnly(beginDateTime) <= todayAsDate()
    },
    isItemBeingEdited(itemId) {
      return itemId === this.editedItem?.id
    },
    setEditTruckItem(item) {
      this.editedItem = this.createEditableItemObject(item)
    },
    createEditableItemObject(item) {
      let newItem = Object.assign({}, item)
      newItem.beginDateTime = formatAsDateOnly(item.beginDateTime)
      newItem.endDateTime = formatAsDateOnly(item.endDateTime)
      newItem.depreciationPerMile = formatDecimalValue(item.depreciationPerMile)
      return newItem
    },
    async saveEditableTruckItem() {
      if (this.$refs.settingsTruckDepreciationEditableField.validate()) {
        let generateDto = this.createPutJunkTruckDto(this.editedItem)
        await this.updateJunkTruck(generateDto).then(() => {
          this.$refs.settingsTruckDepreciationEditableField.resetValidation()
          this.resetEditableTruckItem()
        })
      }
    },
    resetEditableTruckItem() {
      this.editedItem = {}
    },
    createPutJunkTruckDto(item) {
      return {
        id: item.id,
        operatingUnitId: item.operatingUnitId,
        name: item.name,
        depreciationPerMile: item.depreciationPerMile,
        truckFuelTypeId: item.truckFuelTypeId,
        beginDateTime: item.beginDateTime,
        endDateTime: item.endDateTime,
        modifiedBy: this.$msal.getCurrentUserId()
      }
    },
    async scrollToSettingsTrucksCard() {
      if (this.isDisplayPastTrucksEnabled) return await this.$vuetify.goTo("#settings-trucks-card")
    },
    itemRowBackground(item) {
      if (!this.isItemEditable(item)) {
        return "backgroundDisabled"
      }
    },
    trucksDataTableCustomFilter(value, search, item) {
      search = search.toString().toLowerCase()
      return search.length !== 0
        ? item.name.toLowerCase().includes(search) ||
            formatDecimalValue(item.depreciationPerMile).includes(search) ||
            this.getVehicleFuelTypes
              .filter(vft => vft.id === item.truckFuelTypeId)[0].name
              .toLowerCase()
              .includes(search) ||
            dayjs(item.beginDateTime)
              .format("MMMM D, YYYY")
              .toLowerCase()
              .includes(search) ||
            dayjs(item.endDateTime)
              .format("MMMM D, YYYY")
              .toLowerCase()
              .includes(search)
        : true
    },
    async callFetchJunkTrucksByOperatingUnit() {
      await this.fetchJunkTrucksByOperatingUnitIds([this.getSelectedOperatingUnitId])
    },
    getWeeklyAllocationsByJunkTruckId(junkTruckId) {
      let allocs = this.getWeeklyAllocations
      let filtered = allocs.filter(alloc => alloc.junkTruckId === junkTruckId)
        .sort((a,b) => a.dayOfWeekId < b.dayOfWeekId ? -1 : 1)
      return filtered
    }
  },
  computed: {
    ...mapState(useMainStore, ["getIsLoading", "getSelectedOperatingUnitId"]),
    ...mapState(useSettingsStore, [
      "getAllTrucksInOperatingUnitSortedByName",
      "getActiveAndFutureTrucksInOperatingUnitSortedByName",
      "getFranchisesInOperatingUnit",
      "getJunkTrucksLoadingState",
      "getPastTrucksInOperatingUnit",
      "getVehicleFuelTypes",
      "getWeeklyAllocations"
    ]),
    editableItemBeginDateMaximumDate() {
      return this.editedItem?.endDateTime
    },
    editableItemEndDateTimeMinimumValue() {
      return this.editedItem.beginDateTime <= todayAsDate() ? todayAsDate() : this.editedItem.beginDateTime
    },
    trucksInOperatingUnit() {
      return this.isDisplayPastTrucksEnabled ? this.getAllTrucksInOperatingUnitSortedByName : this.getActiveAndFutureTrucksInOperatingUnitSortedByName
    },
    isDisplayPastTrucksToggleDisabled() {
      return this.getPastTrucksInOperatingUnit <= 0 || this.getJunkTrucksLoadingState || this.getIsLoading
    },
    isSearchTextFieldDisabled() {
      return this.trucksInOperatingUnit?.length <= 0 || this.getJunkTrucksLoadingState || this.getIsLoading
    },
    truckHeaders() {
      let headers = [{ text: "Name", align: "start", value: "name" }, { text: "Depreciation Per Mile", align: "center", value: "depreciationPerMile" }, { text: "Fuel", align: "center", sortable: false, value: "truckFuelTypeId" }, { text: "Begin Date", align: "center", value: "beginDateTime" }, { text: "End Date", align: "center", value: "endDateTime" }, { text: "Actions", align: "start", value: "actions", sortable: false, width: "5%" }]
      return this.isActionsDisabled ? headers.slice().filter(headers => headers.text !== "Actions") : headers
    },
    truckScheduleHeaders() {
      let headers = [
        { text: "Day of week", align: "start", value: "day", width: "30%", class: "secondaryDark white--text" },
        { text: "AM Slots", align: "start", value: "amSlots", width: "10%", class: "secondaryDark white--text" },
        { text: "PM Slots", align: "start", value: "pmSlots", width: "10%", class: "secondaryDark white--text" },
        { text: "Dispatch Location", align: "start", value: "dispatchLocationId", width: "40%", class: "secondaryDark white--text" },
        { text: "Actions", align: "start", value: "actions", width: "10%", class: "secondaryDark white--text" }
      ]
      return this.isActionsDisabled ? headers.slice().filter(headers => headers.text !== "Actions") : headers
    }
  },
  async created() {
    if (this.getSelectedOperatingUnitId !== null && this.getSelectedOperatingUnitId !== undefined) {
      await this.callFetchJunkTrucksByOperatingUnit()
    }
  }
}
</script>

<style scoped>
.v-text-field {
  min-width: 175px;
}
</style>
