<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card ref="settings-specialty-items-card-root" :loading="getIsLoadingSpecialtyItems">
      <v-card-title>
        <v-row>
          <v-col cols="auto">
            <span class="headline me-1 word-wrap">Franchise Add-Ons</span>
            <base-tool-tip
              dialog-title="Elect these items for franchises to make them available as add-ons for jobs."
              tooltip-icon="mdi-information-outline"
              icon-color="primary"
              tooltipColor="primary"
              nudge-dialog="50"
              is-bottom-dialog
            />
          </v-col>
          <v-spacer />
          <v-col class="ma-0 pa-0 d-flex flex-fill ms-0 ms-sm-4 ms-md-8 ms-lg-12 ms-xl-6 col-lg-6 col-xl-5" cols="auto">
            <v-switch
              class="ma-0 mx-2 pa-0 justify-end align-end float-end align-self-end word-wrap"
              ref="settings-specialty-items-card-filter-switch"
              label="Include Inactive Add-Ons"
              v-model="isDisplayInactiveSpecialtyItemsEnabled"
              :disabled="isDisplayInactiveSpecialtyItemsToggleDisabled"
              @change="scrollToInactiveSpecialtyItemsCard"
            />
            <v-text-field
              class="ma-0 mx-4 ps-4 float-end d-flex align-self-end flex-fill"
              ref="settings-specialty-items-card-search-text-field"
              label="Search Add-Ons"
              clear-icon="mdi-trash-can-outline"
              append-icon="mdi-magnify"
              v-model.trim="searchText"
              :disabled="isSearchTextFieldDisabled"
              @focus="selectAllContent"
              @input="toUpperCaseSearch"
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-responsive class="overflow-y-auto" max-height="640">
          <v-data-table
            ref="settings-specialty-items-card-data-table"
            item-key="administratorSpecialtyItemId"
            sort-by="name"
            :headers="headers"
            :search="searchText"
            :items="getFilteredSpecialtyItems"
            fixed-header
            single-select
            hide-default-footer
            disable-pagination
            show-expand
            single-expand
          >
            <template v-slot:no-data>
              <v-col class="ma-0 pa-0 align-self-center d-flex">
                <app-not-found title="No Add-Ons!" content="There are currently no active add-ons available.">
                  <template v-slot:actions>
                    <v-btn class="mt-4 primary justify-center align-self-center" ref="settings-specialty-items-card-fetch-button" @click="fetchSpecialtyItems" ripple rounded large>
                      Fetch Add-Ons
                    </v-btn>
                  </template>
                </app-not-found>
              </v-col>
            </template>
            <template v-slot:item.name="{ item }">
              <span class="me-1" ref="settings-specialty-items-card-name">{{ item.name }}</span>
            </template>
            <template v-slot:item.isActive="{ item }">
              <div>
                <v-icon ref="settings-specialty-items-active" v-if="item.isActive" color="success">mdi-check</v-icon>
                <v-icon ref="settings-specialty-items-inactive" v-else color="grey">mdi-close</v-icon>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div ref="settings-specialty-items-card-actions" v-if="item.isActive">
                <v-btn ref="settings-specialty-items-card-actions-add-item-button" color="primary" icon @click="setIsAddFranchiseSpecialtyItemDialogVisible(true, item)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <div ref="settings-specialty-items-card-no-actions" v-else />
            </template>
            <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
              <td class="text-start" ref="settings-specialty-items-card-franchise-elected-expand-cell" v-if="isSubTableVisible(item.franchiseSpecialtyItems)">
                <v-btn
                  ref="settings-specialty-items-card-franchise-elected-expand-button"
                  class="v-data-table__expand-icon"
                  :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                  @click="expand(!isExpanded)"
                  icon
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td ref="settings-specialty-items-card-franchise-elected-expanded" class="pa-0" :colspan="headers.length">
                <v-responsive class="overflow-y-auto" max-height="320">
                  <v-data-table
                    ref="settings-specialty-items-card-franchise-elected-data-table-expanded"
                    item-key="franchiseSpecialtyItemId"
                    no-data-text="No franchises have elected this specialty item."
                    mobile-breakpoint="520"
                    :headers="subHeaders"
                    :items="getFilteredFranchiseSpecialtyItems(item.franchiseSpecialtyItems)"
                    :item-class="itemRowBackground"
                    fixed-header
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:item.franchiseNumberAndName="{ value }">
                      <span ref="settings-specialty-items-card-franchise-elected-name-value-expanded" class="text-md-body-1 grey--text">{{ value }}</span>
                    </template>
                    <template v-slot:item.price="{ value, item }">
                      <v-text-field
                        class="mt-2 mb-n3"
                        ref="settings-specialty-items-card-franchise-elected-price-edit-expanded"
                        v-if="isSpecialtyItemBeingEdited(item.franchiseSpecialtyItemId)"
                        v-model.number="editedSpecialtyItem.price"
                        :rules="editableSpecialtyItemPriceValidationRules"
                        @change="updateEditableSpecialtyItemPrice($event)"
                        @focus="selectAllContent"
                        outlined
                      />
                      <app-number-formatter
                        ref="settings-specialty-items-card-franchise-elected-price-expanded-number-formatter"
                        v-else
                        :amount="value"
                        number-format-style="currency"
                        currency-sign="accounting"
                      />
                    </template>
                    <template v-slot:item.isActive="{ value, item }">
                      <div class="d-flex justify-center" ref="settings-specialty-items-card-editable-is-active" v-if="isSpecialtyItemBeingEdited(item.franchiseSpecialtyItemId)">
                        <v-checkbox ref="settings-specialty-items-card-is-active-checkbox" v-model="editedSpecialtyItem.isActive" color="success" />
                      </div>
                      <div ref="settings-specialty-items-card-viewable-is-active" v-else>
                        <v-icon ref="settings-specialty-items-card-franchise-elected-is-active-formatter-expanded-active" v-if="value" color="success">mdi-check </v-icon>
                        <v-icon ref="settings-specialty-items-card-franchise-elected-is-active-formatter-expanded-inactive" v-else color="grey">mdi-close </v-icon>
                      </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div ref="settings-specialty-items-card-actions-save-container" v-if="isSpecialtyItemBeingEdited(item.franchiseSpecialtyItemId)">
                        <v-btn ref="settings-specialty-items-card-save-item-button" :disabled="isSaveDisabled(item)" @click="saveEditableSpecialtyItem" color="primary" icon>
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn ref="settings-specialty-items-card-cancel-item-button" @click="resetEditableSpecialtyItem" icon>
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </div>
                      <div ref="settings-specialty-items-card-actions-edit-container" v-else-if="isSpecialtyItemEditable(item.administratorSpecialtyItemId)">
                        <v-btn ref="settings-specialty-items-card-franchise-elected-actions-remove-item-button-expanded" color="primary" icon @click="setEditSpecialtyItem(item)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-responsive>
              </td>
            </template>
          </v-data-table>
        </v-responsive>
      </v-card-text>
    </v-card>

    <settings-add-franchise-specialty-item-dialog
      ref="settings-specialty-items-card-add-franchise-dialog"
      v-if="isAddFranchiseSpecialtyItemDialogVisible"
      :is-dialog-visible="isAddFranchiseSpecialtyItemDialogVisible"
      :specialty-item="specialtyItemSelected"
      @closeDialog="setIsAddFranchiseSpecialtyItemDialogVisible(false, {})"
    />
  </v-container>
</template>

<script>
import AppNotFound from "@/components/AppNotFound"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import BaseToolTip from "@/components/BaseToolTip"
import SettingsAddFranchiseSpecialtyItemDialog from "@/components/SettingsAddFranchiseSpecialtyItemDialog"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { editFranchiseSpecialtyItemDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsSpecialtyItemsCard",
  components: { AppNotFound, AppNumberFormatter, BaseToolTip, SettingsAddFranchiseSpecialtyItemDialog },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isDisplayInactiveSpecialtyItemsEnabled: false,
      searchText: "",
      editedSpecialtyItem: {},
      isAddFranchiseSpecialtyItemDialogVisible: false,
      editableSpecialtyItemPriceValidationRules: [value => (value ?? "") >= 0 || "Price cannot be negative."],
      specialtyItemSelected: {}
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getIsLoadingSpecialtyItems", "getSpecialtyItems", "getActiveSpecialtyItems", "getFranchisesInOperatingUnit"]),
    isSearchTextFieldDisabled() {
      return this.getSpecialtyItems.length === 0
    },
    headers() {
      let headers = [
        { text: "Name", align: "start", value: "name" },
        { text: "Is Active", align: "start", value: "isActive" },
        { text: "Actions", align: "end", value: "actions", sortable: false }
      ]

      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    },
    isDisplayInactiveSpecialtyItemsToggleDisabled() {
      return this.getSpecialtyItems.length === 0
    },
    getFilteredSpecialtyItems() {
      return this.isDisplayInactiveSpecialtyItemsEnabled ? this.getSpecialtyItems : this.getActiveSpecialtyItems
    },
    subHeaders() {
      let subHeaders = [
        { text: "Franchise", align: "start", value: "franchiseNumberAndName", class: "secondaryDark white--text" },
        { text: "Price", align: "center", value: "price", class: "secondaryDark white--text" },
        { text: "Is Active", align: "center", value: "isActive", class: "secondaryDark white--text" },
        { text: "Actions", align: "end", value: "actions", sortable: false, class: "secondaryDark white--text" }
      ]

      return this.isActionsDisabled ? subHeaders.slice().filter(header => header.text !== "Actions") : subHeaders
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["fetchSpecialtyItems", "editFranchiseSpecialtyItem"]),
    scrollToInactiveSpecialtyItemsCard() {
      if (this.isDisplayInactiveSpecialtyItemsEnabled) {
        this.$refs["settings-specialty-items-card-root"].$el.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    },
    selectAllContent(event) {
      event.target.select()
    },
    toUpperCaseSearch() {
      this.searchText = this.searchText.toUpperCase()
    },
    isSubTableVisible(franchiseSpecialtyItems) {
      return this.getFilteredFranchiseSpecialtyItems(franchiseSpecialtyItems).length > 0
    },
    getFilteredFranchiseSpecialtyItems(franchiseSpecialtyItems) {
      return franchiseSpecialtyItems.filter(item => this.getFranchisesInOperatingUnit.some(franchise => franchise.franchiseId === item.franchiseId))
    },
    isSpecialtyItemEditable(administratorSpecialtyItemId) {
      return this.getActiveSpecialtyItems.some(activeItem => activeItem.administratorSpecialtyItemId === administratorSpecialtyItemId)
    },
    setEditSpecialtyItem(item) {
      this.editedSpecialtyItem = { ...item }
    },
    isSpecialtyItemBeingEdited(franchiseSpecialtyItemId) {
      return franchiseSpecialtyItemId === this.editedSpecialtyItem?.franchiseSpecialtyItemId
    },
    updateEditableSpecialtyItemPrice(event) {
      if (event === "") {
        event = 0
      }
      this.editedSpecialtyItem.price = event
    },
    isItemNotModified(item) {
      return item.price === this.editedSpecialtyItem.price && item.isActive === this.editedSpecialtyItem.isActive
    },
    isSaveDisabled(item) {
      return this.isItemNotModified(item) || this.getIsLoadingSpecialtyItems || this.editedSpecialtyItem.price < 0
    },
    async saveEditableSpecialtyItem() {
      const dto = editFranchiseSpecialtyItemDto(this.editedSpecialtyItem.franchiseSpecialtyItemId, this.editedSpecialtyItem.price, this.editedSpecialtyItem.isActive)
      await this.editFranchiseSpecialtyItem(dto).then(async () => {
        this.resetEditableSpecialtyItem()
        await this.fetchSpecialtyItems()
      })
    },
    resetEditableSpecialtyItem() {
      this.editedSpecialtyItem = Object.assign({}, {})
    },
    setIsAddFranchiseSpecialtyItemDialogVisible(isVisible, specialtyItem) {
      this.isAddFranchiseSpecialtyItemDialogVisible = isVisible
      this.specialtyItemSelected = Object.assign({}, specialtyItem)
    },
    itemRowBackground() {
      if (!this.$vuetify.breakpoint.xs) return "backgroundDisabled"
    }
  },
  async created() {
    await this.fetchSpecialtyItems()
  }
}
</script>
