<template>
  <v-card ref="job-queue-filters-card" flat>
    <v-card-title class="headline word-wrap">Queue Filters</v-card-title>
    <v-card-subtitle>Select any job in <em class="green--text">Scheduled</em> status to claim.</v-card-subtitle>
    <v-card-text class="pt-1">
      <v-row class="pb-2">
        <v-col cols="auto" class="flex-column d-inline-flex flex-fill flex-wrap">
          <v-select
            class="cursor_pointer"
            :items="getTruckCapacityFilters"
            :value="getSelectedTruckCapacity"
            :disabled="getIsLoadingSlotQueues"
            @input="setSelectedTruckCapacity"
            label="Truck Capacity Up To"
            item-value="id"
            item-text="name"
            clearable
            outlined
            hide-details
            rounded
            return-object
          >
            <template v-slot:selection="{ item }">
              <v-chip>
                <span>{{ item.name }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>

        <v-col cols="auto" class="flex-column d-inline-flex flex-fill flex-wrap">
          <v-select
            class="cursor_pointer"
            :items="getJobStatuses"
            :value="getSelectedStatuses"
            @input="setSelectedStatuses"
            :disabled="getIsLoadingSlotQueues"
            label="Job Statuses"
            item-value="id"
            item-text="name"
            multiple
            clearable
            outlined
            hide-details
            rounded
            deletable-chips
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index <= 1">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text text-caption">
                (+{{ getSelectedStatuses.length - 2 }} others)
              </span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="auto" class="flex-column d-inline-flex flex-fill flex-wrap">
          <v-select
            class="cursor_pointer"
            :items="getFranchisesInOperatingUnit"
            :value="getSelectedFranchiseFilters"
            :disabled="getIsLoadingSlotQueues"
            @input="setSelectedFranchisesFilter"
            label="Franchises"
            item-value="franchiseId"
            item-text="franchiseNameAndNumber"
            multiple
            clearable
            hide-details
            outlined
            rounded
            deletable-chips
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index <= 1">
                <span>{{ item.franchiseNameAndNumber }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text text-caption">
                (+{{ getSelectedFranchiseFilters.length - 2 }} others)
              </span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="auto" class="flex-column d-inline-flex flex-fill flex-wrap">
          <v-select
            class="cursor_pointer"
            :items="getPreferredTimeSlots"
            :value="getPreferredTimeSlotFiltersSelected"
            :disabled="getIsLoadingSlotQueues"
            @input="setPreferredTimeSlotFilter"
            label="Preferred Time Slots"
            item-value="id"
            item-text="name"
            multiple
            clearable
            outlined
            hide-details
            return-object
            rounded
            deletable-chips
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index <= 1">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text text-caption">
                      (+{{ getPreferredTimeSlotFiltersSelected.length - 2 }} others)
                    </span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="auto" class="flex-column d-inline-flex flex-fill flex-wrap">
          <v-select
            class="cursor_pointer"
            :items="getDistanceFromMeFilters"
            :value="getDistanceFromMeFilter"
            :disabled="getIsLoadingSlotQueues"
            @input="setDistanceFromMeFilter"
            label="Distance From Me"
            item-value="id"
            item-text="name"
            clearable
            hide-details
            flat
            outlined
            rounded
            return-object
          >
            <template v-slot:selection="{ item }">
              <v-chip>
                <span>{{ item.name }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "pinia"
import { useMainStore } from "@/stores/Main"
import { useJobQueueStore } from "@/stores/JobQueue"
import { JobStatus } from "@/enums/JobStatus"
import { TruckCapacities } from "@/enums/TruckCapacities"
import { todayAsDate } from "@/utils/DateTimeFormatters"

export default {
  name: "JobQueueFiltersCard",
  data() {
    return {
      minimumDate: todayAsDate()
    }
  },
  methods: {
    ...mapActions(useJobQueueStore, ["setSelectedStatuses", "setDistanceFromMeFilter", "setPreferredTimeSlotFilter", "setSelectedFranchisesFilter", "setSelectedTruckCapacity"])
  },
  computed: {
    ...mapState(useMainStore, ["getJobStatusesSortedByName", "getPreferredTimeSlots", "getTruckCapacities"]),
    ...mapState(useJobQueueStore, [
      "getIsLoadingSlotQueues",
      "getSelectedStatuses",
      "getFranchisesInOperatingUnit",
      "getDistanceFromMeFilter",
      "getDistanceFromMeFilters",
      "getSelectedFranchiseFilters",
      "getPreferredTimeSlotFiltersSelected",
      "getSelectedTruckCapacity"
    ]),
    getTruckCapacityFilters() {
      return this.getTruckCapacities.slice()?.filter(tc => tc.id !== TruckCapacities.FULL.id)
    },
    getJobStatuses() {
      return this.getJobStatusesSortedByName
        .slice()
        ?.filter(js => js.name !== JobStatus.ABANDONED.name
            && js.name !== JobStatus.CANCELLED.name
            && js.name !== JobStatus.CREATED.name)
    }
  }
}
</script>
