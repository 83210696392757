<template>
  <v-layout>
    <v-card class="flex d-flex flex-column" ref="job-franchise-selector-card" :loading="getIsLoadingFranchiseAndSatelliteOffices" elevation="4">
      <v-card-title class="headline">Franchise</v-card-title>
      <v-card-text>
        <div :class="{ cursor_disabled: this.isFranchiseSelectionDisabled }">
          <v-select
            class="mb-4 cursor_pointer"
            ref="job-franchise-selector"
            color="primary"
            label="Selected Franchise"
            item-text="franchiseNameAndNumber"
            :key="franchiseSelectionKey"
            :value="getSelectedFranchise"
            @change="updateSelectedFranchise"
            :items="getUserActiveFranchisesFiltered"
            :disabled="isFranchiseSelectionDisabled"
            :menu-props="{ bottom: true, offsetY: true }"
            return-object
            outlined
            hide-details
          />
        </div>
        <div :class="{ cursor_disabled: this.isFranchiseSatelliteOfficeSelectionDisabled }">
          <v-select
            class="cursor_pointer"
            ref="job-franchise-satellite-office-selector"
            color="primary"
            label="Selected Franchise Satellite Office"
            item-text="name"
            clear-icon="mdi-close-circle"
            clearable
            :key="satelliteOfficeSelectionKey"
            :value="getSelectedSatelliteOffice"
            @change="updateSelectedFranchiseSatelliteOffice"
            :items="getFranchiseSatelliteOfficesInFranchise"
            :disabled="isFranchiseSatelliteOfficeSelectionDisabled"
            :menu-props="{ bottom: true, offsetY: true }"
            return-object
            outlined
            hide-details
          />
        </div>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import { putJunkJobSatelliteOfficeDto } from "@/api/dtos/JunkDtos"
import { dateAsDayOfWeekMonthDayYear } from "@/utils/DateTimeFormatters"
import { mapActions, mapState } from "pinia"
import { useJobStore } from "@/stores/Job"
import { useMainStore } from "@/stores/Main"

export default {
  name: "JobFranchiseSelectorCard",
  props: {
    franchiseSelectionKey: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      satelliteOfficeSelectionKey: 0
    }
  },
  methods: {
    dateAsDayOfWeekMonthDayYear,
    ...mapActions(useJobStore, ["updateJunkJobSelectedFranchiseSatelliteOffice", "fetchJunkJobAddresses"]),
    async updateSelectedFranchise(franchise) {
      this.$emit("updateSelectedFranchise", franchise)
    },
    async updateSelectedFranchiseSatelliteOffice(franchiseSatelliteOffice) {
      this.$refs["job-franchise-satellite-office-selector"].blur()
      const dto = putJunkJobSatelliteOfficeDto(this.getCurrentJob.id, franchiseSatelliteOffice?.id, this.$msal.getCurrentUserId()) // todo: Replace with current user's username when auth is wired up.
      await this.updateJunkJobSelectedFranchiseSatelliteOffice(dto)
        .then(() => {
          this.fetchJunkJobAddresses(this.getCurrentJob.id)
        })
        .catch(() => {
          this.updateSatelliteOfficeSelectionKey()
        })
    },
    updateSatelliteOfficeSelectionKey() {
      this.satelliteOfficeSelectionKey === 1 ? (this.satelliteOfficeSelectionKey = 0) : (this.satelliteOfficeSelectionKey = 1)
    }
  },
  computed: {
    ...mapState(useMainStore, ["getUserActiveFranchises"]),
    ...mapState(useJobStore, [
      "getCurrentJob",
      "getSelectedFranchise",
      "getSelectedSatelliteOffice",
      "getFranchiseSatelliteOfficesInFranchise",
      "getIsLoadingFranchiseAndSatelliteOffices",
      "getIsJobFieldsDisabled"
    ]),
    isFranchiseSelectionDisabled() {
      return this.getIsLoadingFranchiseAndSatelliteOffices || (this.getUserActiveFranchises?.length ?? 0) < 2 || this.getIsJobFieldsDisabled
    },
    isFranchiseSatelliteOfficeSelectionDisabled() {
      return this.getIsLoadingFranchiseAndSatelliteOffices || (this.getFranchiseSatelliteOfficesInFranchise?.length ?? 0) < 1 || this.getIsJobFieldsDisabled
    },
    getUserActiveFranchisesFiltered() {
      return this.getUserActiveFranchises?.filter(franchise => franchise.businessEndDate === null || franchise.businessEndDate >= this.getCurrentJob.scheduledDate) ?? []
    }
  }
}
</script>
