<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-layout column>
        <div class="float-left mb-4">
          <span class="text-md-body-1 font-weight-bold" ref="job-tax-selector-total-calculating" v-if="getIsLoadingTaxes">Calculating...</span>
          <span class="text-md-body-1 font-weight-bold" ref="job-tax-selector-total" v-else>
            Total Taxes Applied:
            <app-number-formatter ref="job-tax-selector-total-formatter" currencySign="accounting" numberFormatStyle="currency" :amount="getTaxAmount" />
          </span>
        </div>
        <v-autocomplete
          ref="job-tax-selector-autocomplete"
          item-text="formattedNameAndRate"
          item-value="franchiseTaxId"
          :label="getComponentTitle"
          :items="getActiveFranchiseTaxes"
          :value="getSelectedTax"
          :key="taxSelectorKey"
          :disabled="isDisabled"
          :loading="isLoading"
          @change="applyTax"
          outlined
        />
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { useJobStore } from "@/stores/Job"
import { mapState, mapActions } from "pinia"
import { putJobSelectedFranchiseTaxDto } from "@/api/dtos/JunkDtos"

export default {
  components: { AppNumberFormatter },
  data() {
    return {
      taxSelectorKey: 0
    }
  },
  computed: {
    ...mapState(useJobStore, [
      "getCurrentJob",
      "getActiveFranchiseTaxes",
      "getIsJobFieldsDisabled",
      "getIsLoadingFranchiseAndSatelliteOffices",
      "getIsLoadingJob",
      "getIsLoadingJobModifiers",
      "getIsLoadingTaxes"
    ]),
    getTaxAmount() {
      return this.getCurrentJob?.totalTax ?? 0
    },
    isTaxExempt() {
      return this.getCurrentJob?.isTaxExempt ?? false
    },
    hasNoAvailableTaxes() {
      return this.getActiveFranchiseTaxes.length === 0
    },
    getComponentTitle() {
      if (this.hasNoAvailableTaxes) return "No Available Taxes"
      if (this.isTaxExempt) return "Tax Exempt"
      return "Selected Tax"
    },
    getSelectedTax() {
      return this.getActiveFranchiseTaxes.find(tax => tax.franchiseTaxId === this.getCurrentJob?.franchiseTaxId) ?? null
    },
    isDisabled() {
      return this.getIsJobFieldsDisabled || this.isLoading || this.hasNoAvailableTaxes || this.isTaxExempt
    },
    isLoading() {
      return this.getIsLoadingFranchiseAndSatelliteOffices || this.getIsLoadingJob || this.getIsLoadingJobModifiers || this.getIsLoadingTaxes
    }
  },
  methods: {
    ...mapActions(useJobStore, ["updateJobSelectedFranchiseTax"]),
    async applyTax(taxId) {
      if (this.getCurrentJob?.franchiseTaxId !== taxId) {
        const dto = putJobSelectedFranchiseTaxDto(this.getCurrentJob.id, taxId)
        await this.updateJobSelectedFranchiseTax(dto).catch(() => this.updateFranchiseKeySelection())
      }
    },
    updateFranchiseKeySelection() {
      this.taxSelectorKey === 1 ? (this.taxSelectorKey = 0) : (this.taxSelectorKey = 1)
    }
  }
}
</script>
