<template>
  <v-container fluid>
    <base-dialog
      ref="job-select-add-ons-dialog-root"
      title="Select Add-Ons"
      dialog-image="mdi-view-grid-plus-outline"
      :is-dialog-visible="isDialogVisible"
      @close-dialog="closeDialog"
    >
      <template class="flex-fill" v-slot:content>
        <v-layout row>
          <v-data-table
            class="flex-fill"
            ref="job-select-add-ons-dialog-data-table"
            item-class="text-md-body-1"
            item-key="franchiseSpecialtyItemId"
            sort-by="name"
            height="32vh"
            :headers="headers"
            :items="jobAddOns"
            fixed-header
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.added="{ item }">
              <v-simple-checkbox ref="job-select-add-ons-dialog-added-checkbox" color="primary" :value="item.added" @input="updateJobAddOnsAdded($event, item)" />
            </template>
            <template v-slot:item.name="{ value }">
              <span class="text-md-body-1">{{ value }}</span>
            </template>
            <template v-slot:item.price="{ item }">
              <app-number-formatter class="text-md-body-1" currencySign="accounting" numberFormatStyle="currency" :amount="item.price" />
            </template>
            <template v-slot:item.quantity="{ item }">
              <v-text-field
                class="mt-4"
                ref="job-select-add-ons-dialog-quantity-text-field"
                type="number"
                min="0"
                max="999"
                v-if="item.added"
                v-model.number="item.quantity"
                :rules="quantityValidationRules"
                @change="updateJobAddOnsQuantity($event, item)"
                @focus="selectAllContent"
                dense
                outlined
              />
            </template>
          </v-data-table>
        </v-layout>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="job-select-add-ons-dialog-cancel-button" color="primaryText" :disabled="false" @click="closeDialog" text rounded ripple>Cancel </v-btn>
        <v-btn class="pa-4" ref="job-select-add-ons-dialog-save-button" color="primary" :loading="isLoading" :disabled="isSaveDisabled" @click="save" text rounded ripple
          >Save
        </v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { useJobStore } from "@/stores/Job"
import { mapActions, mapState } from "pinia"
import { updateJobAddOnsDto } from "@/api/dtos/JunkDtos"

export default {
  name: "JobSelectAddOnsDialog",
  components: { AppNumberFormatter, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      jobAddOns: [],
      quantityValidationRules: [value => value >= 0 || "Quantity cannot be negative"],
      headers: [
        { align: "start", value: "added" },
        { text: "Name", align: "start", value: "name" },
        { text: "Price", align: "start", value: "price" },
        { text: "Quantity", align: "start", value: "quantity" }
      ]
    }
  },
  computed: {
    ...mapState(useJobStore, ["getCurrentJob", "getJobSpecialtyItems", "getJobAddOns", "getIsLoadingJob", "getIsLoadingAddOns"]),
    getEligibleAddOnsForJob() {
      return this.getJobAddOns.map(a => {
        const added = a.quantity > 0
        return {
          ...a,
          added
        }
      })
    },
    hasInvalidQuantityForJobAddOns() {
      return this.jobAddOns.some(jao => jao.quantity < 0)
    },
    isJobAddOnsUnedited() {
      return this.jobAddOns.every(jao => this.getJobAddOns.find(j => j.franchiseSpecialtyItemId === jao.franchiseSpecialtyItemId).quantity === jao.quantity)
    },
    isLoading() {
      return this.getIsLoadingJob || this.getIsLoadingAddOns
    },
    isSaveDisabled() {
      return this.isLoading || this.isJobAddOnsUnedited || this.hasInvalidQuantityForJobAddOns
    }
  },
  methods: {
    ...mapActions(useJobStore, ["updateJobAddOns", "fetchJunkJobByJobId"]),
    selectAllContent(event) {
      event.target.select()
    },
    updateJobAddOnsAdded(event, item) {
      let idx = this.jobAddOns.findIndex(j => j.franchiseSpecialtyItemId === item.franchiseSpecialtyItemId)
      if (~idx) {
        let matchingAddOn = this.jobAddOns[idx]
        matchingAddOn.added = event
        matchingAddOn.quantity = event ? 1 : 0
        this.jobAddOns.splice(idx, 1, matchingAddOn)
      }
    },
    updateJobAddOnsQuantity(event, item) {
      if (event === "") {
        event = 0
      }
      let idx = this.jobAddOns.findIndex(j => j.franchiseSpecialtyItemId === item.franchiseSpecialtyItemId)
      if (~idx) {
        let matchingAddOn = this.jobAddOns[idx]
        matchingAddOn.quantity = event
        if (event === 0) {
          matchingAddOn.added = false
        }
        this.jobAddOns.splice(idx, 1, matchingAddOn)
      }
    },
    async save() {
      const jobAddOnsToUpdate = this.jobAddOns.map(jao => {
        return {
          franchiseSpecialtyItemId: jao.franchiseSpecialtyItemId,
          quantity: jao.quantity === "" ? 0 : jao.quantity
        }
      })

      const dto = updateJobAddOnsDto(this.getCurrentJob.id, jobAddOnsToUpdate)

      await this.updateJobAddOns(dto).then(() => {
        this.fetchJunkJobByJobId(this.getCurrentJob.id)
        this.closeDialog()
      })
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  created() {
    this.jobAddOns = this.getEligibleAddOnsForJob
  }
}
</script>
