<template>
  <v-container class="mt-4" fluid>
    <v-row>
      <v-col>
        <settings-specialty-items-card ref="settings-specialty-items-tab-card" :is-actions-disabled="isActionsDisabled" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsSpecialtyItemsCard from "@/components/SettingsSpecialtyItemsCard"

export default {
  name: "SettingsSpecialtyItemsTab",
  components: {
    SettingsSpecialtyItemsCard
  },
  computed: {
    isActionsDisabled() {
      return !this.$msal.getUserHasWriteSettingsPermission()
    }
  }
}
</script>
