<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card ref="administrator-specialty-items-card-root" :loading="getIsLoadingSpecialtyItems">
      <v-card-title>
        <v-row>
          <v-col cols="auto">
            <span class="headline me-1 word-wrap">Administrator Add-Ons</span>
            <base-tool-tip
              dialog-title="Create or update these items for franchises to use as add-ons for jobs."
              tooltip-icon="mdi-information-outline"
              icon-color="primary"
              tooltipColor="primary"
              nudge-dialog="50"
              is-bottom-dialog
            />
          </v-col>
          <v-spacer />
          <v-col class="ma-0 pa-0 d-flex flex-fill ms-0 ms-sm-4 ms-md-8 ms-lg-12 ms-xl-6 col-lg-6 col-xl-5" cols="auto">
            <v-switch
              class="ma-0 mx-2 pa-0 justify-end align-end float-end align-self-end word-wrap"
              ref="administrator-specialty-items-card-inactive-switch"
              label="Include Inactive Add-Ons"
              v-model="isDisplayInactiveSpecialtyItemsEnabled"
              :disabled="isDisplayInactiveSpecialtyItemsToggleDisabled"
              @change="scrollToInactiveSpecialtyItemsCard"
            />
            <v-text-field
              class="ma-0 mx-4 ps-4 float-end d-flex align-self-end flex-fill"
              ref="administrator-specialty-items-card-search-text-field"
              label="Search Add-Ons"
              clear-icon="mdi-trash-can-outline"
              append-icon="mdi-magnify"
              v-model.trim="searchText"
              :disabled="isSearchTextFieldDisabled"
              @focus="selectAllContent"
              @input="toUpperCaseSearch"
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-responsive class="overflow-y-auto" max-height="640">
          <v-data-table
            ref="administrator-specialty-items-card-data-table"
            item-key="id"
            sort-by="name"
            :headers="headers"
            :search="searchText"
            :items="getFilteredSpecialtyItems"
            fixed-header
            single-select
            hide-default-footer
            disable-pagination
          >
            <template v-slot:no-data>
              <v-col class="ma-0 pa-0 align-self-center d-flex">
                <app-not-found title="No Add-Ons!" content="There are currently no active add-ons available.">
                  <template v-slot:actions>
                    <v-btn
                      class="mt-4 primary justify-center align-self-center"
                      ref="administrator-specialty-items-card-fetch-button"
                      @click="fetchSpecialtyItems"
                      ripple
                      rounded
                      large
                    >
                      Fetch Add-Ons
                    </v-btn>
                  </template>
                </app-not-found>
              </v-col>
            </template>
            <template v-slot:item.name="{ item }">
              <v-text-field
                class="mt-2 mb-n3"
                ref="administrator-specialty-items-card-name-edit"
                v-if="isSpecialtyItemBeingEdited(item.administratorSpecialtyItemId)"
                counter="25"
                v-model.trim="editedSpecialtyItem.name"
                :rules="editableSpecialtyItemNameValidationRules"
                @focus="selectAllContent"
                @input="toUpperCaseName"
                outlined
                dense
              />
              <span v-else class="me-1" ref="administrator-specialty-items-card-name">{{ item.name }}</span>
            </template>
            <template v-slot:item.isActive="{ item }">
              <div class="d-flex justify-center" ref="administrator-specialty-items-card-editable-is-active" v-if="isSpecialtyItemBeingEdited(item.administratorSpecialtyItemId)">
                <v-checkbox ref="administrator-specialty-items-card-is-active-checkbox" v-model="editedSpecialtyItem.isActive" color="success" />
              </div>
              <div ref="administrator-specialty-items-card-viewable-is-active" v-else>
                <v-icon ref="administrator-specialty-items-card-active" v-if="item.isActive" color="success">mdi-check </v-icon>
                <v-icon ref="administrator-specialty-items-card-inactive" v-else color="grey">mdi-close</v-icon>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div ref="administrator-specialty-items-card-actions-save-container" v-if="isSpecialtyItemBeingEdited(item.administratorSpecialtyItemId)">
                <v-btn ref="administrator-specialty-items-card-save-item-button" :disabled="isSaveDisabled(item)" @click="saveEditableSpecialtyItem" color="primary" icon>
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <v-btn ref="administrator-specialty-items-card-cancel-item-button" @click="resetEditableSpecialtyItem" icon>
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </div>
              <div ref="administrator-specialty-items-card-actions-edit-container" v-else class="justify-center">
                <v-btn ref="administrator-specialty-items-card-edit-item-button" @click="setEditSpecialtyItem(item)" color="primary" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-responsive>
      </v-card-text>

      <base-fab ref="administrator-specialty-items-card-fab" :is-visible="true" :fab-options="fabOptions" />

      <administrator-create-specialty-item-dialog
        ref="administrator-specialty-items-card-create-discount-dialog"
        v-if="isCreateSpecialtyItemDialogVisible"
        :is-loading="getIsLoadingSpecialtyItems"
        :is-dialog-visible="isCreateSpecialtyItemDialogVisible"
        @close-dialog="setIsCreateSpecialtyItemDialogVisible(false)"
      />
    </v-card>
  </v-container>
</template>

<script>
import AdministratorCreateSpecialtyItemDialog from "@/components/AdministratorCreateSpecialtyItemDialog"
import AppNotFound from "@/components/AppNotFound"
import BaseFab from "@/components/BaseFab"
import BaseToolTip from "@/components/BaseToolTip"
import { useAdministratorStore } from "@/stores/Administrator"
import { mapActions, mapState } from "pinia"
import { updateAdministratorSpecialtyItemDto } from "@/api/dtos/JunkDtos"

export default {
  name: "AdministratorSpecialtyItemsCard",
  components: { AdministratorCreateSpecialtyItemDialog, AppNotFound, BaseFab, BaseToolTip },
  data() {
    return {
      isDisplayInactiveSpecialtyItemsEnabled: false,
      searchText: "",
      editedSpecialtyItem: {},
      isCreateSpecialtyItemDialogVisible: false,
      editableSpecialtyItemNameValidationRules: [value => ((value ?? "").length > 0 && (value ?? "").length <= 25) || "Name must be less than 25 characters."],
      headers: [
        { text: "Name", align: "start", value: "name" },
        { text: "Active", align: "center", value: "isActive" },
        { text: "Begin Date", align: "center", value: "createdOn" },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ]
    }
  },
  computed: {
    ...mapState(useAdministratorStore, ["getIsLoadingSpecialtyItems", "getSpecialtyItems", "getActiveSpecialtyItems"]),
    isDisplayInactiveSpecialtyItemsToggleDisabled() {
      return this.getSpecialtyItems.length === 0
    },
    isSearchTextFieldDisabled() {
      return this.getSpecialtyItems.length === 0
    },
    getFilteredSpecialtyItems() {
      return this.isDisplayInactiveSpecialtyItemsEnabled ? this.getSpecialtyItems : this.getActiveSpecialtyItems
    },
    fabOptions() {
      return [
        {
          icon: "mdi-view-grid-plus-outline",
          onClickAction: () => this.handleBaseFabClick(true),
          text: "Create Add-On",
          isFabDisabled: false
        }
      ]
    }
  },
  methods: {
    ...mapActions(useAdministratorStore, ["fetchSpecialtyItems", "updateSpecialtyItem"]),
    scrollToInactiveSpecialtyItemsCard() {
      if (this.isDisplayInactiveSpecialtyItemsEnabled) {
        this.$refs["administrator-specialty-items-data-table"].$el.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    },
    selectAllContent(event) {
      event.target.select()
    },
    toUpperCaseSearch() {
      this.searchText = this.searchText.toUpperCase()
    },
    toUpperCaseName() {
      this.editedSpecialtyItem.name = this.editedSpecialtyItem.name.toUpperCase()
    },
    isSpecialtyItemBeingEdited(administratorSpecialtyItemId) {
      return administratorSpecialtyItemId === this.editedSpecialtyItem?.administratorSpecialtyItemId
    },
    setEditSpecialtyItem(item) {
      this.editedSpecialtyItem = Object.assign({}, item)
    },
    isItemNotModified(item) {
      return item.name === this.editedSpecialtyItem.name && item.isActive === this.editedSpecialtyItem.isActive
    },
    isSaveDisabled(item) {
      return this.isItemNotModified(item) || this.getIsLoadingSpecialtyItems || this.editedSpecialtyItem.name.length === 0
    },
    async saveEditableSpecialtyItem() {
      const updatedSpecialtyItemDto = updateAdministratorSpecialtyItemDto(
        this.editedSpecialtyItem.administratorSpecialtyItemId,
        this.editedSpecialtyItem.name,
        this.editedSpecialtyItem.isActive
      )
      await this.updateSpecialtyItem(updatedSpecialtyItemDto).then(() => {
        this.resetEditableSpecialtyItem()
      })
    },
    resetEditableSpecialtyItem() {
      this.editedSpecialtyItem = Object.assign({}, {})
    },
    setIsCreateSpecialtyItemDialogVisible(isVisible) {
      this.isCreateSpecialtyItemDialogVisible = isVisible
    },
    handleBaseFabClick(isVisible) {
      this.resetEditableSpecialtyItem()
      this.setIsCreateSpecialtyItemDialogVisible(isVisible)
    }
  },
  async created() {
    await this.fetchSpecialtyItems()
  }
}
</script>
