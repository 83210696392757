<template>
  <v-card class="flex d-flex flex-column fill-height" ref="job-modifiers-card-root" elevation="4" style="min-width: 200px" :loading="getIsLoading">
    <v-card-title ref="job-modifiers-card-title" class="headline">Modifiers</v-card-title>
    <v-card-text class="mt-n1 px-8 justify-center">
      <v-row dense>
        <v-col class="flex-fill">
          <job-add-on-selector ref="job-modifiers-card-job-add-on-selector" @select-add-ons="handleSelectAddOns" />
        </v-col>
        <v-col class="flex-fill mt-n2">
          <job-discount-selector ref="job-modifiers-card-job-discount-selector" />
        </v-col>
        <v-col class="flex-fill mt-n2">
          <job-tax-selector ref="job-modifiers-card-job-tax-selector" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import JobAddOnSelector from "@/components/JobAddOnSelector"
import JobDiscountSelector from "@/components/JobDiscountSelector"
import JobTaxSelector from "@/components/JobTaxSelector"
import { useJobStore } from "@/stores/Job"
import { mapState } from "pinia"

export default {
  name: "JobModifiersCard",
  components: { JobAddOnSelector, JobDiscountSelector, JobTaxSelector },
  computed: {
    ...mapState(useJobStore, ["getIsLoadingJob", "getIsLoadingJobModifiers"]),
    getIsLoading() {
      return this.getIsLoadingJob || this.getIsLoadingJobModifiers
    }
  },
  methods: {
    handleSelectAddOns() {
      this.$emit("select-add-ons")
    }
  }
}
</script>
