<template>
  <div>
    <v-card ref="dashboard-self-scheduled-jobs-card-root">
      <v-card-title class="headline">
        <v-row>
          <v-col>
            <span class="me-1 word-wrap">Self-Scheduled Jobs</span>
            <base-tool-tip
              ref="dashboard-self-scheduled-jobs-card-tooltip"
              icon-color="primary"
              tooltip-color="primary"
              tooltip-icon="mdi-information-outline"
              :dialog-title="toolTipText"
              :nudge-dialog="getDialogNudgeValue"
              :is-bottom-dialog="true"
            />
          </v-col>
          <v-col>
            <div ref="dashboard-self-scheduled-jobs-card-item-count-caption" class="float-end font-italic word-wrap">{{ getItemsCountCaptionText }}</div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-data-table
            ref="dashboard-self-scheduled-jobs-card-table"
            class="flex-fill"
            item-class="text-md-body-1"
            loading-text="🔎 Fetching Self-Scheduled Jobs 🔍"
            height="31vh"
            :headers="selfScheduledJobHeaders"
            :items="getSelfScheduledJobs"
            :loading="getIsLoadingDashboardSelfScheduledJobs"
            :sort-by="['createdOn']"
            :sort-desc="true"
            hide-default-footer
            disable-pagination
            fixed-header
          >
            <template v-slot:no-data>
              <v-col class="ma-0 pa-0 align-self-center d-flex">
                <app-not-found title="No Jobs!" content="No Self-Scheduled Jobs, in Scheduled status, created in the past 3 days.">
                  <template v-slot:actions><div /></template>
                </app-not-found>
              </v-col>
            </template>
            <template v-slot:item.jobNumber="{ item }">
              <router-link ref="dashboard-self-scheduled-jobs-card-router" class="text-md-body-1" :to="{ name: 'Job', params: { jobId: item.jobId } }">{{ item.jobNumber }}</router-link>
              <v-icon ref="dashboard-self-scheduled-jobs-card-pin-icon" class="ms-2" color="primary" @click="addNewJobTab(item)">
                mdi-pin-outline
              </v-icon>
              <span class="ms-2">
                <base-tool-tip
                  ref="dashboard-self-scheduled-tool-tip"
                  tooltipColor="blue"
                  icon-color="blue"
                  dialog-title="Customer Self-Scheduled"
                  nudge-dialog="0"
                  tooltip-icon="mdi-account-tag-outline"
                  :is-bottom-dialog="true"
                />
              </span>
            </template>
            <template v-slot:item.preferredTimeSlotName="{ item }">
              <v-row class="justify-center text-center align-center" dense>
                <v-col ref="dashboard-self-scheduled-jobs-card-pref-time-slot" v-if="isPreferredTimeSlotTextVisible" cols="auto" class="d-flex">
                  <div ref="dashboard-self-scheduled-jobs-card-pref-time-slot-content">{{ item.preferredTimeSlotName }}</div>
                </v-col>
                <v-col cols="auto" class="d-flex mt-n1">
                  <v-icon ref="dashboard-self-scheduled-jobs-card-pref-time-slot-icon">
                    {{ getPreferredTimeSlotIcon(item.preferredTimeSlotId) }}
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <template ref="dashboard-self-scheduled-jobs-card-created-on-date" v-slot:item.createdOn="{ item }">
              {{ formatDateAsLocale(item.createdOn, "ddd, MMM D h:mm a") }}
            </template>
            <template ref="dashboard-self-scheduled-jobs-card-scheduled-on-date" v-slot:item.scheduledDate="{ item }">
              {{ formatScheduledDate(item.scheduledDate) }}
            </template>
            <template ref="dashboard-self-scheduled-jobs-card-customer-phone" v-slot:item.customerPhone="{ item }">
              {{ formatPhoneNumber(item.customerPhone) }}
            </template>
            <template v-slot:item.isLeadHandled="{ item }">
              <div class="my-n2">
                <v-checkbox
                  ref="dashboard-self-scheduled-jobs-card-lead-handled-checkbox"
                  color="primary"
                  class="align-baseline"
                  :key="leadHandledKey"
                  :input-value="item.isLeadHandled"
                  :disabled="isHandleLeadCheckboxDisabled(item)"
                  @change="promptConfirmationDialog(item, $event)"
                />
              </div>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
    </v-card>
    <app-confirmation-dialog
      ref="job-notes-card-delete-note-confirmation-dialog"
      dialog-image="mdi-checkbox-marked-circle-outline"
      :is-dialog-visible="isHandleLeadConfirmationDialogVisible"
      :is-loading="getIsLoadingDashboardSelfScheduledJobs"
      :title="`Mark ${handleLeadConfirmationJob.jobNumber} as Contacted?`"
      :content-text="`By Clicking 'Confirm' you are verifying that an attempt was made to contact this customer.`"
      @closeDialog="dismissConfirmationDialog"
      @confirmed="createJobLeadWrapper"
    />
  </div>
</template>

<script>
import BaseToolTip from "@/components/BaseToolTip"
import AppNotFound from "@/components/AppNotFound"
import AppConfirmationDialog from "@/components/AppConfirmationDialog"
import { useMainStore } from "@/stores/Main"
import { useDashboardStore } from "@/stores/Dashboard"
import { mapActions, mapState } from "pinia"
import { JobTab } from "@/models/JobTab"
import { postJobLeadDto } from "@/api/dtos/JunkDtos"
import { PreferredTimeSlot } from "@/enums/PreferredTimeSlot"
import phoneNumberFormatter from "@/utils/PhoneNumberFormatter"
import dayjs from "dayjs"

export default {
  name: "DashboardSelfScheduledJobsCard",
  components: { BaseToolTip, AppNotFound, AppConfirmationDialog },
  data() {
    return {
      leadHandledKey: 0,
      isHandleLeadConfirmationDialogVisible: false,
      isPendingLeadHandled: false,
      handleLeadConfirmationJob: {}
    }
  },
  methods: {
    ...mapActions(useMainStore, ["addJobTab"]),
    ...mapActions(useDashboardStore, ["createJobLead"]),
    dayjs,
    formatScheduledDate(date) {
      return dayjs(date).format("ddd, MMM D")
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumberFormatter.formatPhoneNumber(phoneNumber)
    },
    async addNewJobTab(item) {
      let tab = new JobTab(item.jobId, item.jobNumber, item.customerId, item.customerLastName, item.businessName)
      await this.addJobTab(tab)
    },
    getPreferredTimeSlotIcon(preferredTimeSlotId) {
      switch (preferredTimeSlotId) {
        case PreferredTimeSlot.FIRST.id:
          return `mdi-numeric-1-circle`
        case PreferredTimeSlot.AM.id:
          return `mdi-sun-angle`
        case PreferredTimeSlot.PM.id:
          return `mdi-weather-night`
        case PreferredTimeSlot.NONE.id:
          return `mdi-theme-light-dark`
        default:
          return `mdi-blank`
      }
    },
    formatDateAsLocale(dateStr, format) {
      let datetimeUTC = dayjs(`${dateStr}Z`)
      let datetimeLocal = datetimeUTC.local()
      return datetimeLocal.format(format)
    },
    setIsHandleLeadConfirmationDialogVisible(isVisible) {
      this.isHandleLeadConfirmationDialogVisible = isVisible
    },
    promptConfirmationDialog(item, isChecked) {
      this.handleLeadConfirmationJob = Object.assign({}, item)
      this.isPendingLeadHandled = isChecked
      this.setIsHandleLeadConfirmationDialogVisible(true)
    },
    dismissConfirmationDialog() {
      this.toggleLeadHandledKey()
      this.handleLeadConfirmationJob = Object.assign({}, {})
      this.isPendingLeadHandled = false
      this.setIsHandleLeadConfirmationDialogVisible(false)
    },
    async createJobLeadWrapper() {
      let dto = postJobLeadDto(this.handleLeadConfirmationJob.jobId, this.$msal.getCurrentUserId())
      await this.createJobLead(dto).then(() => this.dismissConfirmationDialog())
    },
    isHandleLeadCheckboxDisabled(item) {
      return item.isLeadHandled
    },
    toggleLeadHandledKey() {
      this.leadHandledKey === 0 ? (this.leadHandledKey = 1) : (this.leadHandledKey = 0)
    }
  },
  computed: {
    ...mapState(useDashboardStore, ["getSelfScheduledJobs", "getIsLoadingDashboardSelfScheduledJobs"]),
    selfScheduledJobHeaders() {
      return [
        { text: "Job Number", align: "start", value: "jobNumber" },
        { text: "Customer Name", align: "start", value: "customerName" },
        { text: "Created On", align: "start", value: "createdOn" },
        { text: "Scheduled Date", align: "start", value: "scheduledDate" },
        { text: "Preferred Time", align: "center", value: "preferredTimeSlotName" },
        { text: "Phone", align: "start", value: "customerPhone" },
        { text: "Capacity", align: "start", value: "capacityName" },
        { text: "Initial Contact", align: "start", value: "isLeadHandled" }
      ]
    },
    isPreferredTimeSlotTextVisible() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    toolTipText() {
      let fromDate = dayjs()
        .subtract(2, "day")
        .format("dddd, MMMM D")
      return `Self-Scheduled jobs, in Scheduled status, created since ${fromDate}`
    },
    selfScheduledJobsCount() {
      return this.getSelfScheduledJobs?.length ?? 0
    },
    getItemsCountCaptionText() {
      return this.selfScheduledJobsCount === 1 ? `${this.selfScheduledJobsCount} Self-Scheduled Job` : `${this.selfScheduledJobsCount} Self-Scheduled Jobs`
    },
    getDialogNudgeValue() {
      return this.$vuetify.breakpoint.mobile ? "80" : "150"
    }
  }
}
</script>
