<template>
  <v-card ref="dashboard-open-jobs-card-root" class="flex d-flex flex-column">
    <v-card-title class="headline">
      <v-row>
        <v-col>
          <span class="me-1 word-wrap">Open Jobs</span>
          <base-tool-tip
            ref="dashboard-open-jobs-tool-tip"
            class="ps-4 ms-4"
            dialog-title="Open Jobs/ Needs Closed?"
            icon-color="primary"
            tooltip-color="primary"
            tooltip-icon="mdi-information-outline"
            :is-bottom-dialog="true"
            :nudge-dialog="this.$vuetify.breakpoint.mobile ? '80' : '150'"
          >
            <template v-slot:content>
              <div>Open Jobs will display here matching the following criteria</div>
              <div>
                <ul>
                  <li>Any job with a Scheduled Date of today, in <em>Completed</em> Status.</li>
                  <li>Any job with a Scheduled Date in the past and a Job Status of <em>Scheduled</em>, <em>Claimed</em>, or <em>Completed</em>.</li>
                </ul>
              </div>
            </template>
          </base-tool-tip>
        </v-col>
        <v-col>
          <div ref="dashboard-open-jobs-content" class="float-end font-italic word-wrap">{{ openNumberOfJobsText }}</div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-data-table
          class="overflow-y-auto flex-fill"
          item-class="text-md-body-1"
          loading-text="🔎 Fetching Jobs 🔍"
          height="30vh"
          :headers="tableHeaders"
          :items="getCloseOutReminderJobs"
          :loading="getIsLoadingDashboardCloseOutReminderJobs"
          :sort-by="['scheduledDate']"
          disable-pagination
          fixed-header
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-col class="ma-0 pa-0 align-self-center d-flex">
              <app-not-found title="No Outstanding Open Jobs!">
                <template v-slot:content>
                  <div>No jobs currently exist that match the following criteria: </div>
                  <div>
                    <ul>
                      <li>* Scheduled Date of today and a Status of <em>Completed</em>.</li>
                      <li>* Scheduled Date in the past and a Status of <em>Scheduled</em>, <em>Claimed</em>, or <em>Completed</em></li>
                    </ul>
                  </div>
                </template>
                <template v-slot:actions><div /></template>
              </app-not-found>
            </v-col>
          </template>
          <template v-slot:item.jobNumber="{ item }">
            <span>
              <router-link ref="dashboard-open-jobs-router" class="text-md-body-1" :to="{ name: 'Job', params: { jobId: item.jobId } }">{{ item.jobNumber }}</router-link>
              <v-icon ref="dashboard-open-jobs-pin-icon" class="ms-2" color="primary" @click="addNewJobTab(item)">
                mdi-pin-outline
              </v-icon>
              <span ref="dashboard-open-jobs-tool-tip" v-if="item.jobCreationOriginId === JobCreationOrigin.CUSTOMER.id" class="ms-2">
                <base-tool-tip
                  ref="dashboard-open-jobs-self-scheduled-tool-tip"
                  tooltipColor="blue"
                  icon-color="blue"
                  dialog-title="Customer Self-Scheduled"
                  nudge-dialog="0"
                  tooltip-icon="mdi-account-tag-outline"
                  :is-bottom-dialog="true"
                />
              </span>
            </span>
          </template>
          <template ref="dashboard-open-jobs-scheduled-date" v-slot:item.scheduledDate="{ item }">
            {{ dayjs(item.scheduledDate).format("ddd, MMM D") }}
          </template>
          <template v-slot:item.jobStatusName="{ item }">
            <v-chip ref="dashboard-open-jobs-status" :color="determineBackgroundColor(item.jobStatusName)" :text-color="determineBackgroundColor(item.jobStatusName)" outlined>
              {{ item.jobStatusName }}
            </v-chip>
          </template>
        </v-data-table>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseToolTip from "@/components/BaseToolTip.vue"
import AppNotFound from "@/components/AppNotFound.vue"
import { useMainStore } from "@/stores/Main"
import { useDashboardStore } from "@/stores/Dashboard"
import { mapActions, mapState } from "pinia"
import { JobTab } from "@/models/JobTab"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import { JobCreationOrigin } from "@/enums/JobCreationOrigin"
import dayjs from "dayjs"

export default {
  name: "DashboardOpenJobs",
  components: { BaseToolTip, AppNotFound },
  computed: {
    JobCreationOrigin() {
      return JobCreationOrigin
    },
    ...mapState(useDashboardStore, ["getCloseOutReminderJobs", "getIsLoadingDashboardCloseOutReminderJobs"]),
    tableHeaders() {
      return [
        { text: "Job Number", align: "start", value: "jobNumber" },
        { text: "Customer Name", align: "start", value: "customerName" },
        { text: "Scheduled Date", align: "start", value: "scheduledDate" },
        { text: "Status", align: "start", value: "jobStatusName" }
      ]
    },
    numberOfJobs() {
      return this.getCloseOutReminderJobs.length
    },
    openNumberOfJobsText() {
      return this.numberOfJobs === 1 ? `${this.numberOfJobs} Open Job` : `${this.numberOfJobs} Open Jobs`
    }
  },
  methods: {
    ...mapActions(useMainStore, ["addJobTab"]),
    dayjs,
    determineBackgroundColor(statusName) {
      return getJobStatusColorByStatusName(statusName)
    },
    async addNewJobTab(item) {
      let tab = new JobTab(item.jobId, item.jobNumber, item.customerId, item.customerLastName, item.businessName)
      await this.addJobTab(tab)
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none; /* Remove bullets */
  padding: 0;
  margin: 0;
}
</style>
