<template>
  <v-card id="settings-truck-unavailability-card" class="elevation-4" min-height="225">
    <v-card-title>
      <v-col class="ms-0 mb-4 me-0 mt-0 pa-0 align-self-start d-flex">
        <span id="settings-truck-unavailability-card-title-heading" class="headline word-wrap align-self-start d-flex pe-1">Truck Unavailability</span>
        <base-tool-tip
          class="float-start align-self-start text-start justify-start"
          v-if="isDisplayPastUnavailableTrucksEnabled"
          id="settings-truck-unavailability-card-title-past-trucks-tool-tip"
          dialog-title="Grey Denotes Past Truck"
          tooltip-icon="mdi-information-outline"
          :is-bottom-dialog="true"
        ></base-tool-tip>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0 d-flex flex-fill ms-0 ms-sm-4 ms-md-8 ms-lg-12 ms-xl-6 col-lg-6 col-xl-5" cols="auto">
        <v-switch
          class="ma-0 mx-2 pa-0 justify-end align-end float-end align-self-end word-wrap"
          :disabled="isDisplayPastUnavailableTruckToggleDisabled"
          v-model="isDisplayPastUnavailableTrucksEnabled"
          @click="scrollToSettingsTruckUnavailabilityCard"
          label="Include Past Trucks"
        ></v-switch>
        <v-text-field
          id="settings-truck-unavailability-search-field"
          class="ma-0 mx-4 ps-4 float-end d-flex align-self-end flex-fill"
          :disabled="isSearchTextFieldDisabled"
          v-model="searchText"
          clearable="clearable"
          clear-icon="mdi-trash-can-outline"
          append-icon="mdi-magnify"
          label="Search Trucks"
        />
      </v-col>
    </v-card-title>

    <v-card-text class="mt-n4">
      <v-responsive class="overflow-y-auto" max-height="800">
        <v-data-table
          id="settings-truck-unavailability-table"
          :headers="truckAvailabilityHeaders"
          :items="unavailableTrucksInOperatingUnit"
          :sort-by="['beginDateTime', 'endDateTime']"
          :sort-desc="[false, false]"
          :loading="getTruckUnavailabilityLoadingState"
          loading-text="🔎 Fetching Truck Unavailability Records 🔍"
          :search="searchText"
          :item-class="itemRowBackground"
          :custom-filter="truckUnavailabilityDataTableCustomFilter"
          mobile-breakpoint="840"
          fixed-header
          disable-pagination
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-col class="ma-0 pa-0 align-self-center d-flex">
              <app-not-found title="Ope! No available records :(" content="Looks like you don't have any records, please retry or refresh the page.">
                <template v-slot:actions>
                  <v-btn class="mt-4 primary justify-center align-self-center" ref="settings-truck-unavailability-card-fetch-btn" @click="callFetchUnavailableTrucksByOperatingUnitIds" ripple rounded large>Fetch Unavailable Trucks</v-btn>
                </template>
              </app-not-found>
            </v-col>
          </template>
          <template v-slot:item.truckName="{ item }">
            <div class="text-md-body-1 grey--text">{{ item.truckName }}</div>
          </template>
          <template v-slot:item.beginDateTime="{ item }">
            <app-date-picker-field-selector
              v-if="isItemBeingEdited(item.id)"
              :date-picker-value="editedItem.beginDateTime"
              :minimum-date-value="minimumPickerDate"
              :maximum-date-value="editableItemStartDateMaximumDate"
              :text-field-disabled="isEditableBeginDateTimeFieldDisabled"
              :error-messages="recordAlreadyExistsError"
              @updatedPickerValue="setEditableItemUnavailabilityStartDate($event)"
              :isTextFieldDense="true"
              text-field-format="MMMM D, YYYY"
            ></app-date-picker-field-selector>
            <app-date-time-locale-formatter v-else class="text-md-body-1 grey--text" :date-time-value="item.beginDateTime"></app-date-time-locale-formatter>
          </template>
          <template v-slot:item.endDateTime="{ item }">
            <app-date-picker-field-selector
              v-if="isItemBeingEdited(item.id)"
              :date-picker-value="editedItem.endDateTime"
              :minimum-date-value="editableItemEndDateMinimumValue"
              :error-messages="recordAlreadyExistsError"
              @updatedPickerValue="setEditableItemUnavailabilityEndDate($event)"
              :isTextFieldDense="true"
              text-field-format="MMMM D, YYYY"
            ></app-date-picker-field-selector>
            <app-date-time-locale-formatter v-else class="text-md-body-1 grey--text" :date-time-value="item.endDateTime"></app-date-time-locale-formatter>
          </template>
          <template v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
            <div class="justify-center" v-if="isItemBeingEdited(item.id)">
              <v-btn color="primary" ref="settings-truck-unavailability-save-btn" @click="saveEditableTruckUnavailabilityItem" :disabled="isSaveEditableTruckUnavailabilityItemDisabled" icon>
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn color="red" ref="settings-truck-unavailability-cancel-btn" @click="resetEditableTruckUnavailabilityItem" icon>
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </div>
            <div v-else-if="isItemEditable(item)" class="justify-center">
              <v-btn color="primary" ref="settings-truck-unavailability-edit-btn" @click="setEditableTruckUnavailabilityItem(item)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  ref="settings-truck-unavailability-card-remove-button"
                  icon
                  color="red"
                  v-if="isFutureDate(item)"
                  @click="removeEditableTruckUnavailabilityItem(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-responsive>
    </v-card-text>
  </v-card>
</template>

<script>
import AppDateTimeLocaleFormatter from "@/components/AppDateTimeLocaleFormatter"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import dayjs from "dayjs"
import { dateAsDayOfWeekMonthDayYear, formatAsDateOnly, todayAsDate, tomorrowAsDate } from "@/utils/DateTimeFormatters"
import BaseToolTip from "@/components/BaseToolTip"
import AppNotFound from "@/components/AppNotFound"
import { useMainStore } from "@/stores/Main"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsTruckUnavailabilityCard",
  components: { AppNotFound, BaseToolTip, AppDateTimeLocaleFormatter, AppDatePickerFieldSelector },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editedItem: {},
      isDisplayPastUnavailableTrucksEnabled: false,
      minimumPickerDate: tomorrowAsDate(),
      searchText: ""
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["updateTruckUnavailability", "fetchUnavailableTrucksByOperatingUnitIds", "deleteJunkTruckUnavailability"]),
    isItemBeingEdited(itemId) {
      return itemId === this.editedItem?.id
    },
    isItemEditable(item) {
      return (formatAsDateOnly(item.endDateTime) ?? todayAsDate()) >= todayAsDate()
    },
    isFutureDate(item) {
      return formatAsDateOnly(item.beginDateTime) > todayAsDate()
    },
    itemRowBackground(item) {
      if (!this.isItemEditable(item)) {
        return "backgroundDisabled"
      }
    },
    setEditableTruckUnavailabilityItem(item) {
      this.editedItem = this.createEditableTruckUnavailabilityItem(item)
    },
    async removeEditableTruckUnavailabilityItem(item) {
      await this.deleteJunkTruckUnavailability(item.id)
          .then(() => {
            this.resetEditableTruckUnavailabilityItem()
          })
    },
    createEditableTruckUnavailabilityItem(item) {
      let newEditableItem = Object.assign({}, item)
      newEditableItem.beginDateTime = formatAsDateOnly(item.beginDateTime)
      newEditableItem.endDateTime = formatAsDateOnly(item.endDateTime)
      return newEditableItem
    },
    async saveEditableTruckUnavailabilityItem() {
      if (!this.doesOverlappingRecordExist) {
        let putJunkTruckUnavailabilityDto = this.createPutJunkTruckUnavailabilityDto(this.editedItem)
        await this.updateTruckUnavailability(putJunkTruckUnavailabilityDto).then(() => {
          this.resetEditableTruckUnavailabilityItem()
        })
      }
    },
    async resetEditableTruckUnavailabilityItem() {
      this.editedItem = {}
    },
    setEditableItemUnavailabilityStartDate(dateEvent) {
      this.editedItem.beginDateTime = dateEvent
    },
    setEditableItemUnavailabilityEndDate(dateEvent) {
      this.editedItem.endDateTime = dateEvent
    },
    createPutJunkTruckUnavailabilityDto(item) {
      return {
        id: item.id,
        junkTruckId: item.junkTruckId,
        beginDateTime: item.beginDateTime,
        endDateTime: item.endDateTime,
        modifiedBy: this.$msal.getCurrentUserId()
      }
    },
    scrollToSettingsTruckUnavailabilityCard() {
      if (this.isDisplayPastUnavailableTrucksEnabled) return this.$vuetify.goTo("#settings-truck-unavailability-card")
    },
    truckUnavailabilityDataTableCustomFilter(value, search, item) {
      search = search.toString().toLowerCase()
      return search.length !== 0
        ? item.truckName.toLowerCase().includes(search) ||
            dayjs(item.beginDateTime)
              .format("MMMM D, YYYY")
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            dayjs(item.endDateTime)
              .format("MMMM D, YYYY")
              .toLowerCase()
              .includes(search.toLowerCase())
        : true
    },
    async callFetchUnavailableTrucksByOperatingUnitIds() {
      await this.fetchUnavailableTrucksByOperatingUnitIds([this.getSelectedOperatingUnitId])
    },
    generateErrorMessage() {
      const truckName = this.findOverlappingRecords[0].truckName
      const beginDateFormatted = dateAsDayOfWeekMonthDayYear(this.findOverlappingRecords[0].beginDateTime)
      const endDateFormatted = dateAsDayOfWeekMonthDayYear(this.findOverlappingRecords[0].endDateTime)
      return [`Sorry, a conflicting record was found for '${truckName}'! Range: ${beginDateFormatted} to ${endDateFormatted}.`]
    }
  },
  computed: {
    ...mapState(useMainStore, ["getIsLoading", "getSelectedOperatingUnitId"]),
    ...mapState(useSettingsStore, ["getAllUnavailableTrucks", "getActiveAndFutureUnavailableTrucks", "getPastUnavailableTrucks", "getTruckUnavailabilityLoadingState"]),
    // ...mapGetters(["getIsLoading", "getSelectedOperatingUnitId"]),
    editableItemStartDateMaximumDate() {
      return this.editedItem?.endDateTime
    },
    editableItemEndDateMinimumValue() {
      return this.editedItem.beginDateTime <= todayAsDate() ? todayAsDate() : this.editedItem.beginDateTime
    },
    isEditableBeginDateTimeFieldDisabled() {
      return formatAsDateOnly(this.editedItem.beginDateTime) <= todayAsDate()
    },
    unavailableTrucksInOperatingUnit() {
      return this.isDisplayPastUnavailableTrucksEnabled ? this.getAllUnavailableTrucks : this.getActiveAndFutureUnavailableTrucks
    },
    isDisplayPastUnavailableTruckToggleDisabled() {
      return this.getPastUnavailableTrucks.length <= 0 || this.getTruckUnavailabilityLoadingState || this.getIsLoading
    },
    isSearchTextFieldDisabled() {
      return this.unavailableTrucksInOperatingUnit.length <= 0 || this.getTruckUnavailabilityLoadingState || this.getIsLoading
    },
    recordAlreadyExistsError() {
      return this.doesOverlappingRecordExist ? this.generateErrorMessage() : []
    },
    doesOverlappingRecordExist() {
      return this.findOverlappingRecords?.length > 0
    },
    findOverlappingRecords() {
      return this.getAllUnavailableTrucks
        ?.slice()
        .filter(
          ut =>
            ut.id !== this.editedItem.id &&
            ut.junkTruckId === this.editedItem.junkTruckId &&
              (
                  (formatAsDateOnly(ut.beginDateTime) <= formatAsDateOnly(this.editedItem.beginDateTime) &&
                      formatAsDateOnly(this.editedItem.beginDateTime) <= formatAsDateOnly(ut.endDateTime)) ||
                  (formatAsDateOnly(ut.beginDateTime) <= formatAsDateOnly(this.editedItem.endDateTime) &&
                      formatAsDateOnly(this.editedItem.endDateTime) <= formatAsDateOnly(ut.endDateTime)) ||
                  (formatAsDateOnly(this.editedItem.beginDateTime) <= formatAsDateOnly(ut.beginDateTime) &&
                      formatAsDateOnly(ut.endDateTime) <= formatAsDateOnly(this.editedItem.endDateTime))
              ))
    },
    isSaveEditableTruckUnavailabilityItemDisabled() {
      return this.getTruckUnavailabilityLoadingState || this.doesOverlappingRecordExist
    },
    truckAvailabilityHeaders() {
      let headers = [
        { text: "Name", value: "truckName", align: "start" },
        { text: "Begin Date", value: "beginDateTime", align: "center" },
        { text: "End Date", value: "endDateTime", align: "center" },
        { text: "Actions", value: "actions", align: "center", sortable: false, width: "10%" }
      ]
      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    }
  },
  async created() {
    if (this.getSelectedOperatingUnitId !== null && this.getSelectedOperatingUnitId !== undefined) {
      await this.callFetchUnavailableTrucksByOperatingUnitIds()
    }
  }
}
</script>

<style scoped></style>
