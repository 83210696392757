<template>
  <v-card class="mx-auto" max-width="550" min-height="200" flat>
    <v-card-title ref="job-receipt-job-detail-confirmation-card-title" class="text-h6 justify-center pt-2 word-wrap text-break">{{ getCardTitle }}</v-card-title>
    <v-card-text class="px-12">

      <v-divider class="mb-4" />

      <v-row>
        <v-col class="my-4 px-8 text-center">
          <v-row class="text-h5">
            <span ref="job-receipt-job-detail-confirmation-card-capacity-name-title"><strong>Capacity</strong></span>
            <v-spacer />
            <span ref="job-receipt-job-detail-confirmation-card-capacity-name-content">{{ getCurrentJob.capacityName }}</span>
          </v-row>
          <v-row class="text-h5">
            <span ref="job-receipt-job-detail-confirmation-card-price-title"><strong>Price</strong></span>
            <v-spacer />
            <app-number-formatter ref="job-receipt-job-detail-confirmation-card-price-content" :amount="getCurrentJob.priceWithAddOns" currencySign="accounting" numberFormatStyle="currency" />
          </v-row>
          <v-row v-if="hasDiscount" class="text-h5">
            <span ref="job-receipt-job-detail-confirmation-card-discount-title"><strong>Discount</strong></span>
            <v-spacer />
            <app-number-formatter ref="job-receipt-job-detail-confirmation-card-discount-content" :amount="getDiscountAmount" :display-negative-as-red="false" currencySign="accounting" numberFormatStyle="currency" />
          </v-row>
          <v-row class="text-h5">
            <span ref="job-receipt-job-detail-confirmation-card-total-tax-title"><strong>Tax</strong></span>
            <v-spacer />
            <app-number-formatter ref="job-receipt-job-detail-confirmation-card-total-tax-content" :amount="getCurrentJob.totalTax" currencySign="accounting" numberFormatStyle="currency" />
          </v-row>
          <v-row class="text-h5">
            <span ref="job-receipt-job-detail-confirmation-card-total-tip-title"><strong>Tip</strong></span>
            <v-spacer />
            <app-number-formatter ref="job-receipt-job-detail-confirmation-card-total-tip-content" :amount="getCurrentJob.totalTip" currencySign="accounting" numberFormatStyle="currency" />
          </v-row>
          <v-row class="text-h5">
            <span ref="job-receipt-job-detail-confirmation-card-total-price-title"><strong>Total</strong></span>
            <v-spacer />
            <app-number-formatter ref="job-receipt-job-detail-confirmation-card-total-price-content" :amount="getCurrentJob.totalPrice" currencySign="accounting" numberFormatStyle="currency" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <div ref="job-receipt-job-detail-confirmation-card-info-div"><strong>Make any necessary corrections before advancing.</strong></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { isObjectEmpty } from "@/utils/ObjectHelpers"
import { mapState } from "pinia"
import { useJobStore } from "@/stores/Job"

export default {
  name: "JobReceiptJobDetailConfirmationCard",
  components: { AppNumberFormatter },
  computed: {
    ...mapState(useJobStore, ["getCurrentJob", "getSelectedJobDiscount"]),
    getCardTitle() {
      return `${this.$msal.getCurrentUserGivenName()}, everything look good?`
    },
    hasDiscount() {
      return !isObjectEmpty(this.getSelectedJobDiscount)
    },
    getDiscountAmount() {
      return this.getCurrentJob.discountAmount > 0 ? this.getCurrentJob.discountAmount * -1 : this.getCurrentJob.discountAmount ?? 0
    }
  }
}
</script>
