<template>
  <v-container fluid>
    <base-dialog
      ref="job-add-ons-non-transferable-dialog-root"
      title="Non-Transferable Add-Ons"
      dialog-image="mdi-view-grid-plus-outline"
      :is-dialog-visible="isDialogVisible"
      @close-dialog="cancel"
    >
      <template class="flex-fill" v-slot:content>
        <v-layout row class="text-center">
          <v-card-text>
            The items listed below are not available for the franchise <strong>{{ selectedFranchise.franchiseName }}.</strong> If you still wish to change the franchise, these
            items will be removed.
          </v-card-text>
        </v-layout>
        <v-layout row>
          <v-data-table
            class="flex-fill"
            ref="job-add-ons-non-transferable-dialog-data-table"
            item-class="text-md-body-1"
            item-key="franchiseSpecialtyItemId"
            sort-by="name"
            height="32vh"
            :headers="headers"
            :items="nonTransferableJobAddOns"
            fixed-header
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.name="{ value }">
              <span class="text-md-body-1">{{ value }}</span>
            </template>
            <template v-slot:item.price="{ value }">
              <app-number-formatter class="text-md-body-1" currencySign="accounting" numberFormatStyle="currency" :amount="value" />
            </template>
            <template v-slot:item.quantity="{ value }">
              <span class="text-md-body-1">{{ value }}</span>
            </template>
          </v-data-table>
        </v-layout>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="job-add-ons-non-transferable-dialog-cancel-button" color="primaryText" :disabled="false" @click="cancel" text rounded ripple>Cancel </v-btn>
        <v-btn class="pa-4" ref="job-add-ons-non-transferable-dialog-save-button" color="primary" @click="confirm" text rounded ripple>Continue</v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppNumberFormatter from "@/components/AppNumberFormatter"

export default {
  name: "JobAddOnsNonTransferableDialog",
  components: { AppNumberFormatter, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    selectedFranchise: {},
    nonTransferableJobAddOns: []
  },
  data() {
    return {
      headers: [
        { text: "Name", align: "start", value: "name" },
        { text: "Price", align: "center", value: "price" },
        { text: "Quantity", align: "end", value: "quantity" }
      ]
    }
  },
  methods: {
    confirm() {
      this.$emit("confirmUpdateFranchise")
    },
    cancel() {
      this.$emit("cancelUpdateFranchise")
    }
  }
}
</script>
